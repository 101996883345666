import dayjs from '#app/utils/custom_dayjs';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import LocalOfferRoundedIcon from '@mui/icons-material/LocalOfferRounded';
import EventRoundedIcon from '@mui/icons-material/EventRounded';
import NewReleasesRoundedIcon from '@mui/icons-material/NewReleasesRounded';
import { createElement } from "react";

/**
 * ボタンのタイプ.
 */
// type CallToActionType = "ACTION_TYPE_UNSPECIFIED" | "BOOK" | "ORDER" | "SHOP" | "LEARN_MORE" | "SIGN_UP" | "GET_OFFER" | "CALL"
export const CallToActionType = {
	ACTION_TYPE_UNSPECIFIED: "ACTION_TYPE_UNSPECIFIED",
	BOOK: "BOOK",
	ORDER: "ORDER",
	SHOP: "SHOP",
	LEARN_MORE: "LEARN_MORE",
	SIGN_UP: "SIGN_UP",
	GET_OFFER: "GET_OFFER",
	CALL: "CALL",
} as const;
export type CallToActionType = typeof CallToActionType[keyof typeof CallToActionType];
export const getCallToActionTypeLabel = (v: CallToActionType) => {
	switch (v) {
		case CallToActionType.ACTION_TYPE_UNSPECIFIED: return "なし";
		case CallToActionType.BOOK: return "予約";
		case CallToActionType.ORDER: return "オンライン注文";
		case CallToActionType.SHOP: return "購入";
		case CallToActionType.LEARN_MORE: return "詳細";
		case CallToActionType.SIGN_UP: return "登録";
		case CallToActionType.GET_OFFER: return "GET_OFFER";//非推奨らしい.
		case CallToActionType.CALL: return "電話";
		default: return "";
	}
}
type CallToAction = {
	actionType: CallToActionType;
	url: string;
}
/**
 * イベント.
 */
type LocalPostEvent = {
	title: string;
	schedule: TimeInterval;
}
type Date = {
	year: number | null;
	month: number | null;
	day: number | null;
}
const dateToString = (date: Date | null) => {
	if (date === null || date.year === null || date.month === null || date.day === null) { return ""; }
	const ret = dayjs().year(date.year).month(date.month - 1).date(date.day);
	return ret.isValid() ? ret.format("YYYY-MM-DD") : "";
}
const stringToDate = (str: string): Date => {
	const d = dayjs(str);
	return { year: d.year(), month: d.month() + 1, day: d.date() }
}
type TimeOfDay = {
	hours: number | null;
	minutes: number | null;
	seconds: number | null;
	nanos: number | null;
}
const timeToString = (time: TimeOfDay | null) => {
	if (time === null || time.hours === null) { return ""; }
	const ret = dayjs().hour(time.hours).minute(time.minutes || 0).second(time.seconds || 0);
	return ret.isValid() ? ret.format("HH:mm") : "";
}
const stringToTime = (str: string): TimeOfDay => {
	if (!str) return { hours: null, minutes: null, seconds: null, nanos: null };
	const d = dayjs("2022-01-01" + str);//日付も入れないとフォーマットされてくれぬ.
	return { hours: d.hour(), minutes: d.minute(), seconds: d.second(), nanos: null }
}
type TimeInterval = {
	startDate: Date;
	startTime: TimeOfDay;
	endDate: Date;
	endTime: TimeOfDay;
}

type MediaFormat = "MEDIA_FORMAT_UNSPECIFIED" | "PHOTO" | "VIDEO";
type Category = "CATEGORY_UNSPECIFIED" | "COVER" | "PROFILE" | "LOGO" | "EXTERIOR" | "INTERIOR" | "PRODUCT" | "AT_WORK" | "FOOD_AND_DRINK" | "MENU" | "COMMON_AREA" | "ROOMS" | "TEAMS" | "ADDITIONAL";
type LocationAssociation = {
	category: Category;
	priceListItemId: string|null;
}
type Dimensions = {
	widthPixels: number;
	heightPixels: number;
}
type MediaInsights = {
	viewCount: number;//ドキュメントではstring(int64 format)となっているが、numberで表現できるやろたぶん.
}
type Attribution = {
	profileName: string;
	profilePhotoUrl: string;
	takedownUrl: string;
	profileUrl: string;
}
/**
 * メディアアイテムやで〜
 */
export class MediaItem {
	name: string = "";
	mediaFormat: MediaFormat = "PHOTO";
	locationAssociation: LocationAssociation = { category: "ADDITIONAL", priceListItemId: null };
	googleUrl?: string = undefined;
	thumbnailUrl?: string = undefined;
	createTime?: string = undefined;
	dimensions?: Dimensions = undefined;
	insights?: MediaInsights = undefined;//投稿だと取れへん..
	attribution?: Attribution = undefined;
	description?: string = undefined;
	sourceUrl: string = "";
	/**
	 * コンストラクタ.
	 */
	constructor(args: any = {}) {
		this.name = args.name ? args.name : this.name;
		this.mediaFormat = args.mediaFormat ? args.mediaFormat : this.mediaFormat;
		this.locationAssociation = args.locationAssociation ? args.locationAssociation : this.locationAssociation;
		this.googleUrl = args.googleUrl ? args.googleUrl : this.googleUrl;
		this.thumbnailUrl = args.thumbnailUrl ? args.thumbnailUrl : this.thumbnailUrl;
		this.createTime = args.createTime ? args.createTime : this.createTime;
		this.dimensions = args.dimensions ? args.dimensions : this.dimensions;
		this.insights = args.insights ? args.insights : this.insights;
		this.attribution = args.attribution ? args.attribution : this.attribution;
		this.description = args.description ? args.description : this.description;
		this.sourceUrl = args.sourceUrl ? args.sourceUrl : this.sourceUrl;
	}
}

type LocalPostState = "LOCAL_POST_STATE_UNSPECIFIED" | "REJECTED" | "LIVE" | "PROCESSING";

/**
 * トピックの種類.
 */
// type LocalPostTopicType = "LOCAL_POST_TOPIC_TYPE_UNSPECIFIED" | "STANDARD" | "EVENT" | "OFFER" | "ALERT";
// export const ALL_LocalPostTopicType = ["LOCAL_POST_TOPIC_TYPE_UNSPECIFIED", "STANDARD", "EVENT", "OFFER", "ALERT"] as const;
// export type LocalPostTopicType = (typeof ALL_LocalPostTopicType)[number];
export const LocalPostTopicType = {
	LOCAL_POST_TOPIC_TYPE_UNSPECIFIED: "LOCAL_POST_TOPIC_TYPE_UNSPECIFIED",
	STANDARD: "STANDARD",
	EVENT: "EVENT",
	OFFER: "OFFER",
	ALERT: "ALERT",
} as const;
export type LocalPostTopicType = typeof LocalPostTopicType[keyof typeof LocalPostTopicType];
export const getLocalPostTopicTypeLabel = (v: LocalPostTopicType) => {
	switch (v) {
		case LocalPostTopicType.LOCAL_POST_TOPIC_TYPE_UNSPECIFIED: return "タイプ無し";
		case LocalPostTopicType.STANDARD: return "最新情報";
		case LocalPostTopicType.EVENT: return "イベント";
		case LocalPostTopicType.OFFER: return "クーポン";
		case LocalPostTopicType.ALERT: return "COVID-19 の最新情報";
		default: return "";
	}
}


type AlertType = "ALERT_TYPE_UNSPECIFIED" | "COVID_19";
type LocalPostOffer = {
	couponCode: string;
	redeemOnlineUrl: string;
	termsConditions: string;
};
/**
 * ビジネスプロフィールの投稿オブジェクト.  
 * Doc: https://developers.google.com/my-business/reference/rest/v4/accounts.locations.localPosts
 */
export class LocalPost {
	name: string = "";
	summary: string = "";
	callToAction: CallToAction | null = null;
	createTime: string = "";
	updateTime: string = "";
	event: LocalPostEvent | null = null;
	state: LocalPostState = "LOCAL_POST_STATE_UNSPECIFIED";
	media: MediaItem[] | null = null;
	searchUrl: string = "";
	topicType: LocalPostTopicType = LocalPostTopicType.LOCAL_POST_TOPIC_TYPE_UNSPECIFIED;
	alertType: AlertType | null = null;
	offer: LocalPostOffer | null = null;
	getCallToActionTypeLabel() {
		return "Yep! " + this.callToAction?.actionType;
	}
	getTopicTypeLabel(): string {
		return getLocalPostTopicTypeLabel(this.topicType);
	}
	getTopicTypeIcon(): React.ReactElement | undefined {
		switch (this.topicType) {
			case "STANDARD": return createElement(NewReleasesRoundedIcon);
			case "EVENT": return createElement(EventRoundedIcon);
			case "OFFER": return createElement(LocalOfferRoundedIcon);
			case "ALERT": return createElement(ReportProblemRoundedIcon);
		}
	}
	getActionTypeLabel(): string {
		if (!this.callToAction) return "";
		return getCallToActionTypeLabel(this.callToAction.actionType);
	}
	getStateLabel(): string {
		switch (this.state) {
			case "LOCAL_POST_STATE_UNSPECIFIED": return "未公開";
			case "REJECTED": return "リジェクト";
			case "LIVE": return "公開済み";
			case "PROCESSING": return "処理中";
			default: return "";
		}
	}
	getStateColor(): "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined {
		switch (this.state) {
			case "LOCAL_POST_STATE_UNSPECIFIED": return "default";
			case "REJECTED": return "error";
			case "LIVE": return "primary";
			case "PROCESSING": return "warning";
		}
	}
	/**
	 * 画像周り.
	 */
	getThumbnailUrl (index: number = 0) {
		const media = this.getMedia(index);
		return media?.thumbnailUrl ? media.thumbnailUrl : this.getMediaUrl(index);
	}
	getMediaUrl(index: number = 0): string {
		const media = this.getMedia(index);
		if (!media) return "";
		if (media.googleUrl) return media.googleUrl;
		return media.sourceUrl ? media.sourceUrl : "";
	}
	getMedia(index: number = 0): MediaItem | null {
		if (!this.media) return null;
		return this.media[index];
	}
	deleteMedia(index: number) {
		if (!this.media) return null;
		this.media.splice(index, 1);
	}
	addMedia(sourceUrl: string) {
		if (!this.media) this.media = [];
		this.media.push(new MediaItem({
			mediaFormat: "PHOTO",
			sourceUrl: sourceUrl,
		}));
	}
	/**
	 * 各機能を設定できる投稿タイプか.
	 */
	canSetButton(): boolean {
		const allows: LocalPostTopicType[] = [LocalPostTopicType.ALERT, LocalPostTopicType.STANDARD, LocalPostTopicType.EVENT];
		return allows.includes(this.topicType);
	}
	canSetMedia(): boolean {
		const allows: LocalPostTopicType[] = [LocalPostTopicType.OFFER, LocalPostTopicType.STANDARD, LocalPostTopicType.EVENT];
		return allows.includes(this.topicType);
	}
	canSetEvent(): boolean {
		const allows: LocalPostTopicType[] = [LocalPostTopicType.OFFER, LocalPostTopicType.EVENT];
		return allows.includes(this.topicType);
	}
	canSetOffer(): boolean {
		const allows: LocalPostTopicType[] = [LocalPostTopicType.OFFER];
		return allows.includes(this.topicType);
	}
	/**
	 * イベントの日付範囲取得.
	 */
	getEventRangeLabel(): string {
		let label = "";
		if (!this.event || !this.event.schedule) return label;
		const std = this.event.schedule.startDate;
		const stt = this.event.schedule.startTime;
		if (std) {
			label += std.year + "年" + std.month + "月" + std.day + "日";
			if (stt.hours) {
				label += ", " + stt.hours + ":" + (stt.minutes || "00") + "";
			}
		}
		label += " - ";
		const edd = this.event.schedule.endDate;
		const edt = this.event.schedule.endTime;
		if (edd) {
			label += edd.year + "年" + edd.month + "月" + edd.day + "日";
			if (edt && edt.hours !== null) {
				label += ", " + edt.hours + ":" + (edt.minutes || "00") + "";
			}
		}
		return label;
	}
	/**
	 * イベント初期化.
	 */
	private getInitEvent(): LocalPostEvent {
		if (this.event) return this.event;
		return {
			title: "",
			schedule: {
				startDate: { year: null, month: null, day: null },
				startTime: { hours: null, minutes: null, seconds: null, nanos: null },
				endDate: { year: null, month: null, day: null },
				endTime: { hours: null, minutes: null, seconds: null, nanos: null },
			}
		}
	}
	setEventTitle(title: string) { this.event = this.getInitEvent(); this.event.title = title; }
	getEventStartDate() { return dateToString(this.getInitEvent().schedule.startDate) }
	getEventStartTime() { return timeToString(this.getInitEvent().schedule.startTime) }
	getEventEndDate() { return dateToString(this.getInitEvent().schedule.endDate) }
	getEventEndTime() { return timeToString(this.getInitEvent().schedule.endTime) }
	setEventStartDate(str: string) { this.event = this.getInitEvent(); this.event.schedule.startDate = stringToDate(str); }
	setEventStartTime(str: string) { this.event = this.getInitEvent(); this.event.schedule.startTime = stringToTime(str); }
	setEventEndDate(str: string) { this.event = this.getInitEvent(); this.event.schedule.endDate = stringToDate(str); }
	setEventEndTime(str: string) { this.event = this.getInitEvent(); this.event.schedule.endTime = stringToTime(str); }

	/**
	 * オファー初期化.
	 */
	private getInitOffer(): LocalPostOffer {
		if (this.offer) return this.offer;
		return { couponCode: "", redeemOnlineUrl: "", termsConditions: "" }
	}
	setOfferCouponCode(couponCode: string) { this.offer = this.getInitOffer(); this.offer.couponCode = couponCode; }
	setOfferRedeemOnlineUrl(redeemOnlineUrl: string) { this.offer = this.getInitOffer(); this.offer.redeemOnlineUrl = redeemOnlineUrl; }
	setOfferTermsConditions(termsConditions: string) { this.offer = this.getInitOffer(); this.offer.termsConditions = termsConditions; }

	/**
	 * コンストラクタ.
	 */
	constructor(args: any = {}) {
		this.name = args.name ? args.name : this.name;
		this.summary = args.summary ? args.summary : this.summary;
		this.callToAction = args.callToAction ? args.callToAction : this.callToAction;
		this.createTime = args.createTime ? args.createTime : this.createTime;
		this.updateTime = args.updateTime ? args.updateTime : this.updateTime;
		this.event = args.event ? args.event : this.event;
		this.state = args.state ? args.state : this.state;
		this.media = args.media ? args.media : this.media;
		this.searchUrl = args.searchUrl ? args.searchUrl : this.searchUrl;
		this.topicType = args.topicType ? args.topicType : this.topicType;
		this.alertType = args.alertType ? args.alertType : this.alertType;
		this.offer = args.offer ? args.offer : this.offer;
	}
}