import { useNickFetch } from "#app/hooks/useNickFetch";
import { useShopPageProps } from "#app/layouts/ShopLayout";
import { Photo } from "#app/models";
import PhotoEditDialog from "#app/pages/Shops/Photos/PhotoEditDialog";
import dayjs from "#app/utils/custom_dayjs";
import NoImage from "#assets/no-image.svg";
import ModeEditRoundedIcon from '@mui/icons-material/ModeEditRounded';
import { Box, Button, Card, CardActionArea, CardActions, CardMedia, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";

type PhotoListResponse = {
	photos: Photo[],
}

export default function ShopPhotos() {
	const { shop, reloadShop } = useShopPageProps();
	const [selectedPhoto, setSelectedPhoto] = useState<Photo | null>(null);
	const [photoList, setPhotoList] = useState<Photo[]>();
	const { nickFetch } = useNickFetch();

	const refleshPhotos = async () => {
		const res = await nickFetch("/get_photos/", {
			shop_id: shop.id,
		});
		const data = res.data as PhotoListResponse;
		if (!res.error && data.photos) {
			// ok.
			const tmp = [];
			for (const photo of data.photos) {
				tmp.push(new Photo(photo));
			}
			setPhotoList(tmp);
		}
	}

	useEffect(() => {
		(async function () {
			await refleshPhotos();
		})();
	}, []);

	return (
		<div>
			<Box pb={3}>
				<Typography mt={2} mb={3} sx={{ fontSize: ".9rem", fontWeight: "bold" }}>写真</Typography>
				<Button variant="contained" onClick={() => { setSelectedPhoto(new Photo()) }}>新規作成</Button>
			</Box>
			<PhotoEditDialog
				open={selectedPhoto !== null}
				shop_id={shop.id}
				photo_id={selectedPhoto?.id}
				onClose={() => { setSelectedPhoto(null) }}
				onComplete={refleshPhotos}
			/>

			<Grid container spacing={2}>
				{photoList?.map((photo, index) => {
					const mediaItem = photo.get_media_item();
					const media_url = mediaItem.googleUrl ? mediaItem.googleUrl : mediaItem.sourceUrl;
					const thumbnailUrl = mediaItem.thumbnailUrl ? mediaItem.thumbnailUrl : null;
					return (
						<Grid key={index} item xl={2} lg={3} md={4} xs={6}>
							<Card sx={{ position: "relative" }}>
								<Box sx={{ px: 1, py: 0.5, backgroundColor: "rgba(0,0,0,0.03)" }}>
									<Tooltip title={photo.getStatusDetail()} placement="top">
										<Typography variant="caption">{photo.getStatusLabel()}</Typography>
									</Tooltip>
									<Typography variant="caption" mx="4px" color="#888">/</Typography>
									<Typography variant="caption">{photo.getSourcePlatformLabel()}</Typography>
								</Box>
								<CardActions sx={{position:"relative",p:0}}>
									<CardMedia
										component="img"
										height="220px"
										sx={{ backgroundColor: "#FFF" }}
										image={thumbnailUrl || media_url || NoImage}
										alt="画像"
									/>
									<Box sx={{ height: "80px", background: "linear-gradient(rgba(0,0,0,.54),transparent)", position: "absolute", top: 0, width: "100%" }}>
										<Typography variant="body2" color="#fff" ml={1} mt={1}>
											<>{dayjs(mediaItem.createTime).isValid() ? "投稿: " + dayjs(mediaItem.createTime).format("YYYY年M月D日(dd)") : ""}</>
										</Typography>
									</Box>
								</CardActions>
								<CardActions sx={{ py: 0, backgroundColor: "rgba(0,0,0,0.03)" }}>
									{/* <Box sx={{ py: 1, pl: 1 }}>
										<Typography sx={{ fontSize: ".75rem" }}>{photo.getStatusLabel()}</Typography>
										<Typography sx={{fontSize:".75rem"}}>{dayjs(photo.post_dt).format("YYYY-M-D HH:mm")}</Typography>
										<Typography sx={{ fontSize: ".75rem" }}>{photo.getSourcePlatformLabel()}</Typography>
									</Box> */}
									<Typography sx={{ fontSize: ".75rem" }}>{photo.platform_post_id}</Typography>
									<IconButton
										aria-label="settings" sx={{ ml: "auto" }}
										onClick={() => { setSelectedPhoto(photo); }}
									>
										<ModeEditRoundedIcon />
									</IconButton>
								</CardActions>
							</Card>
						</Grid>
					)
				})}
			</Grid>
		</div>
	);
}
