import { createElement } from "react";


/**
 */
export const sleep = (msec: number) => new Promise(resolve => setTimeout(resolve, msec));

/**
 */
export const nl2br = (text: string) => {
	const regex = /(\n)/g
	return text.split(regex).map(function (line, i) {
		if (line.match(regex)) {
			return createElement("br", { key: i })
		} else {
			return line;
		}
	});
}
