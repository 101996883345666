import { useEffect, useState } from "react";
import { User } from "#app/models";
import { useNickFetch } from "#app/hooks/useNickFetch";
import { useNavigate } from "react-router-dom";
import { useViewer } from "./useViewer";

/**
 * ユーザーを取得.
 * ユーザーID == ViewerID だったらviewerのほうも更新する.
 * @param user_id 
 */
export function useUserGetter(user_id: number) {
	const [user, setUser] = useState<User>(new User());
	const navigate = useNavigate();
	const {viewer, setViewer} = useViewer();
	const { nickFetch } = useNickFetch();

	const refleshUser = async function () {
		const res = await nickFetch("/get_user", { user_id: user_id });
		if (res.error) {
			// エラー起きたらトップにリダイレクト.
			return navigate("/", { replace: true });
		}
		const new_user = new User(res.data.user);
		setUser(new_user);
		// viewerと同じIDならviewrのほうも更新しちゃう.
		if (viewer.id === user.id) {
			setViewer(new_user);
		}
	}
	useEffect(() => {
		refleshUser();
	}, []);//空配列を渡すことでマウント時にだけ呼ばれる.

	return { user, refleshUser };
}