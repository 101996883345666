import { useViewer } from "#app/hooks/useViewer";
import { useNavigate } from "react-router-dom";
import { UserAuthLevel } from "#app/models";
import { useEffect } from "react";

export default function Home() {
	const { viewer } = useViewer();
	const navigate = useNavigate();

	/**
	 * 通常権限はマイページへリダイレクト.
	 */
	useEffect(() => {
		if ( Number(viewer.auth_level) !== Number(UserAuthLevel.MASTER) ) {
			navigate("/users/"+viewer.id, { replace: true });
		} else {
			// 管理権限でも管理トップで見せるもの無いのでusersへ飛ばす.
			navigate("/users", { replace: true });
		}
	}, []);

	return (
		<div className="Home">
			ホーム画面です<br />
			Hello {viewer.name}<br />
		</div>
	);
}
