import dayjs from '#app/utils/custom_dayjs';
import { LocalPost, MediaItem } from "./LocalPost";

/**
 * ユーザー.
 */
export interface User {
	[key: string]: any,//キーがstringだよって事定義しないと怒られる.
}
export class User {
	id: number = 0;
	login_id: string = "";
	login_dt: string | null = null;
	status: number = 0;
	name: string = "";
	auth_level: UserAuthLevel | null = null;
	email: string = "";
	agency: string = "";
	agency_label: string = "";
	stripe_customer_id: string = "";
	free_initial_cost: boolean = false;
	subscription_cost: number = 0;
	max_shop_num: number = 0;
	google_account_id: string | null = null;
	regist_dt: string | null = null;
	payment_start_date: string | null = null;
	google_account: {
		id: string, name: string, picture: string,
	} | null = null;
	constructor(args: any = null) {
		for (const key in args) {
			if (key in this) {
				if ( key == "free_initial_cost" ) {
					this[key] = parseInt(args[key]) ? true : false;
				} else if ( key == "subscription_cost" ) {
						this[key] = parseInt(args[key]);
				} else if ( key == "status" ) {
						this[key] = parseInt(args[key]);
				} else {
					this[key] = args[key];
				}
			}
		}
	}
	isMaster = (): boolean => {
		return this.auth_level == UserAuthLevel.MASTER;
	}
}

/**
 * 権限.
 */
 export const UserAuthLevel = {
	MASTER: 0,
	NOARMAL: 10,	// 投稿待ち.
} as const;
export type UserAuthLevel = typeof UserAuthLevel[keyof typeof UserAuthLevel];

/**
 * 投稿と写真の共通部品.
 */
class PostMediaBase {
	id: number = 0;
	shop_id: number = 0;
	source_platform: number = 0;
	status: PostStatus = 0;
	error_message: string = "";
	post_dt: string = "";
	edit_dt: string = "";
	regist_dt: string = "";
	platform_post_id: string = "";
	/**
	 * 作成元.
	 */
	getSourcePlatformLabel = (): string => {
		switch (this.source_platform) {
			case 1: return "管理画面で登録";
			case 10: return "ホトペブログから取り込み";
			case 11: return "ホトペクーポンから取り込み";
			case 12: return "ホトペスタイルから取り込み";
			case 20: return "インスタグラムから取り込み";
			default: return "";
		}
	}
	/**
	 * 投稿ステータス.
	 */
	getStatusLabel = (): string => {
		switch (this.status) {
			case PostStatus.DRAFT: return "下書き";
			case PostStatus.POSTING_WAIT: return "投稿待ち";
			case PostStatus.POSTING_PROC: return "投稿中";
			case PostStatus.POSTING_DONE: return "投稿済み";
			case PostStatus.POSTING_ERROR: return "投稿エラー";
			case PostStatus.DELETE_WAIT: return "削除待ち";
			case PostStatus.DELETE_PROC: return "削除中";
			case PostStatus.DELETE_DONE: return "削除済み";
			case PostStatus.DELETE_ERROR: return "削除エラー";
			case PostStatus.IGNORE: return "無視";
			default: return "";
		}
	}
	getStatusDetail = (): string => {
		switch (this.status) {
			case PostStatus.POSTING_WAIT:
				return this.post_dt ? (dayjs(this.post_dt).format("YYYY-MM-DD HH:mm") + " に投稿予定") : "";
			case PostStatus.POSTING_ERROR:
			case PostStatus.DELETE_ERROR:
				return this.error_message;
			default: return "";
		}
	}
}
/**
 * 投稿の状態.
 */
export const PostStatus = {
	NONE: 0,
	DRAFT: 1,			// 下書き.
	POSTING_WAIT: 10,	// 投稿待ち.
	POSTING_PROC: 12,	// 投稿中.
	POSTING_DONE: 14,	// 投稿済み.
	POSTING_ERROR: 16,	// 投稿エラー.
	DELETE_WAIT: 20,	// 削除待ち.
	DELETE_PROC: 22,	// 削除中.
	DELETE_DONE: 24,	// 削除済み.
	DELETE_ERROR: 26,	// 削除済み.
	IGNORE: 99,	// 無視.
} as const;
export type PostStatus = typeof PostStatus[keyof typeof PostStatus];

/**
 * 投稿.
 */
export interface Post {
	[key: string]: any,//キーがstringだよって事定義しないと怒られる.
}
export class Post extends PostMediaBase {
	local_post_json: string = "";
	local_post_name: string = "";
	// LocalPost取得.
	get_local_post = (): LocalPost => {
		if (!this.local_post_json) return new LocalPost({ topicType: "STANDARD" });
		return new LocalPost(JSON.parse(this.local_post_json));
	}
	set_local_post = (localPost: LocalPost) => {
		this.local_post_json = JSON.stringify(localPost);
	}
	constructor(args: any = null) {
		super();
		for (const key in args) {
			if (key in this) {
				this[key] = args[key];
			}
		}
		this.source_platform = (typeof this.source_platform === "string") ? parseInt(this.source_platform) : this.source_platform;
		this.status = (typeof this.status === "string") ? parseInt(this.status) as PostStatus : this.status;
	}
}

/**
 * 写真.
 */
export interface Photo {
	[key: string]: any,//キーがstringだよって事定義しないと怒られる.
}
export class Photo extends PostMediaBase {
	media_item_json: string = "";
	media_item_name: string = "";
	// media_item取得.
	get_media_item = (): MediaItem => {
		if (!this.media_item_json) return new MediaItem();
		return new MediaItem(JSON.parse(this.media_item_json));
	}
	set_media_item = (mediaItem: MediaItem) => {
		this.media_item_json = JSON.stringify(mediaItem);
	}
	constructor(args: any = null) {
		super();
		for (const key in args) {
			if (key in this) {
				this[key] = args[key];
			}
		}
		this.source_platform = (typeof this.source_platform === "string") ? parseInt(this.source_platform) : this.source_platform;
		this.status = (typeof this.status === "string") ? parseInt(this.status) as PostStatus : this.status;
	}
}


/**
 * ショップ.
 */
export interface Shop { [key: string]: any }
export class Shop {
	id: number = 0;
	google_location_id: string = "";
	title: string = "";
	type: ShopType = ShopType.NONE;
	hpb_salon_id: string = "";
	constructor(args: any = null) {
		for (const key in args) {
			if (key in this) {
				if ( key == "type" ) {
					this[key] = parseInt(args[key]) as ShopType;
				} else {
					this[key] = args[key];
				}
			}
		}
	}
	getTypeLabel = (): string => {
		switch ( this.type as ShopType ) {
			case ShopType.NONE: return "";
			case ShopType.HAIR_SALON: return "ヘアサロン";
			case ShopType.KIREI_SALON: return "キレイサロン";
			case ShopType.OTHER: return "その他";
			default: return "a";
		}
	}
}
export const ShopType = {
   NONE: 0,
   HAIR_SALON: 1,
   KIREI_SALON: 2,
   OTHER: 99,
} as const;
export type ShopType = typeof ShopType[keyof typeof ShopType];

/**
 * 投稿の状態.
 */
 export const SyncStatus = {
	NONE: 0,
	PROCESSING: 1,
	DONE: 2,
	ERROR: 3,
} as const;
export type SyncStatus = typeof SyncStatus[keyof typeof SyncStatus];

/**
 * 同期情報.
 */
export interface ShopSync { [key: string]: any }
export class ShopSync {
	id: number = 0;
	instagram_user_id: number = 0;
	shop_id: number = 0;
	source_platform: number = 0;
	enabled: boolean = false;
	post_status: PostStatus = 0;
	start_date: string | null = null;
	update_dt: string | null = null;
	status: SyncStatus = 0;
	log_line: string | null = null;
	constructor(args: any = null) {
		for (const key in args) {
			if (key in this) {
				if (key == "enabled") {
					this[key] = Boolean(parseInt(args[key]));
				} else if (key == "status") {
					this[key] = parseInt(args[key]) as SyncStatus;
				} else {
					this[key] = args[key];
				}
			}
		}
	}
	getStatusLabel = (): string => {
		switch ( this.status ) {
			case SyncStatus.NONE: return "待機";
			case SyncStatus.PROCESSING: return "同期中";
			case SyncStatus.DONE: return "完了";
			case SyncStatus.ERROR: return "エラー";
			default: return "";
		}
	}
}