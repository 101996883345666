import { User } from "#app/models";
import { useNavigate } from "react-router-dom";
import { useNotice } from "./useNotice";
import { useViewer } from "./useViewer";
import { SERVER_HOST, SERVER_SCHEME } from "#app/const";

/**
 * にっくAPI叩いた後に返す値.
 */
class NickFetchResponse {
	public error: boolean = false;
	public message: string = "";
	public status: number = 0;
	public data: any = null;//jsonをコンバートしたやーつ.
}

/**
 * にっくAPIサーバーへリクエスト投げる.
 * エラー処理どうしようかなぁ.
 * 失敗しても何かしらはオブジェクト返すか、throwしちゃうか. -> 返すようにした.
 */
const sendNickFetchRequest = async function (action: string, body: object | null = null): Promise<NickFetchResponse> {
	const params: RequestInit = {
		method: "POST",
		body: body ? JSON.stringify(body) : null,
		credentials: "include",
	};
	const endpoint = SERVER_SCHEME+"://"+SERVER_HOST+"/api/";
	action = action[0] === "/" ? action.substring(1) : action;//最初のスラッシュは消す.
	const res = await fetch(endpoint + action, params);
	const res_data = await res.json().catch(_ => null);
	const ret = new NickFetchResponse();
	ret.status = res.status;
	ret.data = res_data;
	if (!res.ok) {
		ret.error = true;
		if (res_data) {
			// 意図して出したエラー.
			let display_message = res_data.message;
			try {
				const message_data = JSON.parse(res_data.message);
				console.log(message_data);
				if ( message_data && message_data.error && message_data.error.status ) {
					// おそらくGoogleから返ってきたレスポンス.
					if ( message_data.error.status === "PERMISSION_DENIED" ) {
						display_message = "権限エラー : " + message_data.error.status;
					}
				}
			} catch ( e ) {
				// console.log("had err");
			}
			ret.message = display_message;
		} else {
			// 知らんエラー.
			ret.message = "通信に失敗しました(" + ret.status + ")";
		}
	}
	return ret;
}

/**
 * NickサーバーへAPI投げるフック.
 * @returns 
 */
export function useNickFetch() {
	const { addNotice } = useNotice();
	const navigate = useNavigate();
	const { setViewer } = useViewer();
	/**
	 * 外で同じエラー処理を何度もしなくていいようにここで共通のエラー処理やっちゃう.
	 * 外で独自のエラー処理する時はどうやって？
	 */
	const nickFetch = async function (action: string, body: object | null = null): Promise<NickFetchResponse> {
		const res = await sendNickFetchRequest(action, body);
		if (res.error) {
			addNotice(res.message, "error", "bottom");
			if (res.status === 401) {
				// ログインしてね.
				setViewer(new User());//空にしておく.
				navigate("/login", { replace: true });
			}
		}
		return res;
	}
	return { nickFetch };

}