import { useNickFetch } from "#app/hooks/useNickFetch";
import { useNotice } from "#app/hooks/useNotice";
import { useViewer } from "#app/hooks/useViewer";
import { User } from "#app/models";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import { Avatar, Box, Grid, Link, Paper, TextField, Typography } from "@mui/material";
import { useState } from "react";
import LoginBg from "#assets/login_bg.png";

function Copyright(props: any) {
	return (
		<Box {...props}>
			<Typography variant="body2" align="center">
				<Link color="inherit" href="https://hicheez.jp/privacy/" target="_blank">
					プライバシーポリシー
				</Link>
			</Typography>
			<Typography variant="body2" align="center">
				{'Copyright © '}
				<Link color="inherit" href="mailto:n.tanaka@syukyaku-mania.com">
					集客マニア
				</Link>{' '}
				{new Date().getFullYear()}
				{'.'}
			</Typography>
		</Box>
	);
}

export default function Login() {
	const [failLogin, setFailLogin] = useState(false);
	const [loading, setLoading] = useState(false);
	const { setViewer } = useViewer();
	const { addNotice } = useNotice();
	const { nickFetch } = useNickFetch();

	/**
	 * ログインフォームサブミットされた.
	 */
	const handleSubmit = async function (event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();
		setLoading(true);
		setFailLogin(false);
		const formData = new FormData(event.currentTarget);
		// alert("ログインID: " + formData.get("login_id") + "\nパス: " + formData.get("password"));
		/**
		 * ログインAPI叩く.
		 */
		const res = await nickFetch("/login", {
			login_id: formData.get("login_id"),
			password: formData.get("password"),
		});
		/**
		 * ログイン後の処理.
		 * 成功・・・次のページにリダイレクト.
		 * 失敗・・・なんかする.
		 */
		if (res.data.success) {
			const res2 = await nickFetch("/get_viewer");
			if (res2.data.viewer) {
				setViewer(new User(res2.data.viewer));//viewerセットすればトップページ飛ぶようになってる.
				addNotice("サインイン成功", "success");
			} else {
				alert("viewer取得に失敗");
			}
		} else {
			setFailLogin(true);
		}
		setLoading(false);
	}

	// パスワードを忘れた場合のリンク.
	const app_host = window.location.host.replace("dashboard.", "");
	const password_forget_url = "https://"+app_host+"/login/forget/";

	return (
		<>
			<Grid container component="main" sx={{ height: '100vh' }}>
				<Grid
					item
					xs={false}
					sm={4}
					md={7}
					sx={{
						// backgroundImage: 'url(https://source.unsplash.com/random)',
						backgroundImage: `url(${LoginBg})`,
						backgroundRepeat: 'no-repeat',
						backgroundColor: (t) =>
							t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
						backgroundSize: 'cover',
						backgroundPosition: 'center',
					}}
				/>
				<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
					<Box
						sx={{
							my: 8,
							mx: 4,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
							<LockOutlinedIcon />
						</Avatar>
						<Typography component="h1" variant="h5">サインイン</Typography>
						<Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
							<TextField
								margin="normal"
								required
								fullWidth
								label="ログインID"
								name="login_id"
								error={failLogin}
							/>
							<TextField
								margin="normal"
								required
								fullWidth
								name="password"
								label="パスワード"
								type="password"
								error={failLogin}
							/>
							<LoadingButton
								type="submit"
								fullWidth
								variant="contained"
								loading={loading}
								sx={{ mt: 3, mb: 2 }}
							>
								サインイン
							</LoadingButton>
							{failLogin ? (<Typography sx={{ mb: 2 }} align="center" color="error">
								サインインに失敗
							</Typography>) : null}
							<Grid container>
								<Grid item xs>
									<Link href={password_forget_url} variant="body2">
										パスワードを忘れた場合
									</Link>
								</Grid>
							</Grid>
						</Box>
						<Copyright sx={{ mt: 5 }} />
					</Box>
				</Grid>
			</Grid>
		</>
	);
}

