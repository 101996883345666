import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollTop() {
	const { pathname } = useLocation();

	useEffect(() => {
		// ページ遷移で1番上にスクロール.
		window.scrollTo(0, 0);
		// react-routerのバグなのか謎だけど、bodyに overflow:hidden が付いてスクロールできなくなる現象がある.
		// 対処療法的だが overflow:auto を付与して潰す.
		setTimeout(() => {
			document.body.style.overflow = "auto";
		}, 0);
	}, [pathname]);

	return null;
}

export default ScrollTop;