import { useDrawerOpen } from "#app/hooks/useDrawerOpen";
import { useNickFetch } from "#app/hooks/useNickFetch";
import { useViewer } from '#app/hooks/useViewer';
import { User } from '#app/models';
import { darkModeState } from "#app/states";
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import HeaderLogo from "#assets/header-logo.png";
import { AppBar, Avatar, Box, Button, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState } from "recoil";

export default function AppDashboard() {
	const { viewer, setViewer } = useViewer();
	const { nickFetch } = useNickFetch();
	const handleLogout = async function (event: any) {
		event.preventDefault();
		await nickFetch("/logout");
		setViewer(new User());
	}

	const [anchorEl, setAnchorEl] = useState(null);
	const handleMenu = (event: any) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const [darkMode, setDarkMode] = useRecoilState(darkModeState);
	const switchDarkMode = () => {
		setDarkMode(!darkMode);
	}

	const { toggleDrawerOpen } = useDrawerOpen();
	const inShopsPage = -1 < window.location.href.indexOf("/shops/");

	return (
		<>
			<AppBar position="fixed" color='inherit' sx={{ borderBottom: "1px solid #e5e5e5;" }} elevation={0}>{/* elevation 0 で影消せる. */}
				{viewer.isMaster() ? (<>
					<Box px={2} sx={{background:"#eee", borderBottom:"1px solid #ccc"}}>
						<Button component={Link} to='/users'>管理アカウント一覧</Button>
						{/* <Button component={Link} to='/shops'>店舗一覧</Button> */}
					</Box>
				</>) : null}
				<Toolbar variant="regular">{/* dense でもよい */}
					<IconButton
						size="large"
						edge="start"
						color="inherit"
						aria-label="menu"
						onClick={toggleDrawerOpen}
						sx={{ mr: 2, display: inShopsPage ? "block" : "none" }}
					>
						<MenuIcon />
					</IconButton>
					<Box sx={{ flexGrow: 1, display: "flex" }}>
						<Button disableRipple component={Link} to='/' sx={{ color: "inherit", pl: 0, "&:hover": { "background": "inherit" } }}>
							{/* <Typography variant="h6" component="div">
								Cheez
							</Typography> */}
							<img src={HeaderLogo} style={{width:"128px",height:"40px"}} alt="Cheez" />
						</Button>
					</Box>
					{/* <IconButton
						size="large"
						onClick={switchDarkMode}
						color="inherit"
					>
						{darkMode ? <LightModeIcon /> : <DarkModeIcon />}
					</IconButton> */}
					<div>
						<Button
							color="inherit"
							onClick={handleMenu}
						>
							<Avatar
								src={viewer.google_account?.picture}
								sx={{ width: 32, height: 32, bgcolor: "#eee" }}
							/>
							<span style={{ marginLeft: ".4rem" }}>{viewer.name}</span>
						</Button>
						<Menu
							id="menu-appbar"
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={handleClose}
						>
							<MenuItem component={Link} to={"/users/" + viewer.id} onClick={handleClose}>
								<ListItemIcon><PersonIcon /></ListItemIcon>
								<ListItemText>マイアカウント</ListItemText>
							</MenuItem>
							<Divider />
							<MenuItem onClick={handleLogout}>
								<ListItemIcon><LogoutIcon /></ListItemIcon>
								<ListItemText>サインアウト</ListItemText>
							</MenuItem>
						</Menu>
					</div>
				</Toolbar>
			</AppBar>
		</>
	);
}
