import { useUserGetter } from "#app/hooks/useUserGetter";
import EditDialog from "#app/pages/Users/Detail/EditDialog";
import Shops from "#app/pages/Users/Detail/Shops";
import GoogleIcon from "#assets/google.png";
import { useNickFetch } from "#app/hooks/useNickFetch";
import { useNotice } from "#app/hooks/useNotice";
import LaunchIcon from '@mui/icons-material/Launch';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import dayjs from "#app/utils/custom_dayjs";
import { Avatar, Box, Button, Card, CardActions, CardContent, Divider, Grid, Tab, Typography } from "@mui/material";
import { SetStateAction, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { SERVER_HOST, SERVER_SCHEME } from "#app/const";


type StripeCard = {
	brand: string,
	last4: string,
	exp_year: string,
	exp_month: string,
}

export default function UserDetail() {
	const location = useLocation();
	const { nickFetch } = useNickFetch();
	const { addNotice } = useNotice();
	const navigate = useNavigate();
	const params = useParams();
	const user_id = Number(params.user_id);
	const { user, refleshUser } = useUserGetter(user_id);
	const [stripeCard, setStripeCard] = useState<StripeCard>();

	// タブ切り替え.
	// ハッシュで表示タブ判定してるけど、URLにしたほうがいいかもしれない.
	const TabPageList = ["tab1", "tab2", "tab3"] as const;//TODO: ダサいから機能名にする.
	type TabPage = typeof TabPageList[number];// https://dackdive.hateblo.jp/entry/2019/08/13/090000
	const hash_val = location.hash.replace("#", "") as TabPage;
	const selected_tab = TabPageList.includes(hash_val) ? hash_val : TabPageList[0];
	const [currentTab, setCurrentTab] = useState<TabPage>(selected_tab);
	const handleTabChange = (e: React.SyntheticEvent, newValue: SetStateAction<TabPage>) => {
		setCurrentTab(newValue);
		navigate("#" + newValue, { replace: true });
	};

	// Googleアカウント連携するURL.
	const google_auth_url = SERVER_SCHEME + "://" + SERVER_HOST + "/google/auth/?user_id=" + user_id + "&from=" + encodeURIComponent(window.location.href);

	// カード変更URL.
	const card_change_url = SERVER_SCHEME + "://" + SERVER_HOST + "/payment/setup/?cid=" + user.stripe_customer_id;

	// 編集ダイアログ開閉.
	const [editDialogOpen, setEditDialogOpen] = useState(false);

	const load_card_detail = async function () {
		const res = await nickFetch("/get_user_card/", {
			user_id: user_id,
		});
		if (!res.error) {
			// ok.
			setStripeCard(res.data.stripe_card as StripeCard);
		}
	}

	useEffect(() => {
		(async function () {
			await load_card_detail();
		})();
	}, []);

	const handle_remove_google_auth = async function () {
		if ( window.confirm("本当に連携解除しますか？") ) {
			const res = await nickFetch("/remove_google_auth/", {
				user_id: user.id,
			});
			if (!res.error) {
				// ok.
				await refleshUser();
				addNotice("Google連携を解除しました", "success");
			}
		}
	}

	return (
		<>
			<Grid container spacing={2}>
				<Grid item md={4} xs={12}>
					<Box mb={2}>
						<Card>
							<CardContent>
								<Box sx={{ display: "flex" }}>
									<Avatar
										src={user.google_account?.picture}
										sx={{ width: 120, height: 120, bgcolor: "#eee" }}
									/>
									<Box pl={3}>
										<Typography mb={1} variant="subtitle1">{user.name}</Typography>
										<Box sx={{ display: "flex", mb: 0 }}>
											<Typography sx={{ mr: 1 }}>ID:</Typography>
											<Typography >{user.login_id}</Typography>
										</Box>
										<Typography>{user.email}</Typography>
										{user.login_dt ? (
											<Typography title={String(user.login_dt)}>{dayjs(user.login_dt).fromNow()} にログイン</Typography>
										) : null}
										{user.id && !user.status ? (
											<Typography color={"red"}>非公開</Typography>
										) : null}
									</Box>
								</Box>
							</CardContent>
							<Divider />
							<CardActions>
								<Button variant="outlined" onClick={() => { setEditDialogOpen(true) }}>編集</Button>
								<EditDialog
									open={editDialogOpen}
									onClose={() => { setEditDialogOpen(false); }}
									onComplete={refleshUser}
									user={user}
								/>
							</CardActions>
						</Card>
					</Box>
					{user.stripe_customer_id ? (
						<Box mb={2}>
							<Card>
								<CardContent>
									<Box sx={{ display: "flex" }}>
										<Box mr="auto">
											<Typography sx={{ fontSize: ".9rem", fontWeight: "bold" }} mb={2}>クレジットカード情報</Typography>
										</Box>
										<Button variant="outlined" size="small" href={card_change_url} target="_blank" startIcon={<LaunchIcon />}>変更</Button>
									</Box>
									<Box sx={{ display: "flex" }}>
										{stripeCard ? (
											<Box>
												<Typography>
													{stripeCard?.brand} **** **** **** {stripeCard?.last4}
												</Typography>
												<Typography>{stripeCard?.exp_year}年{stripeCard?.exp_month}月に有効期限切れ</Typography>
											</Box>
										) : null}
									</Box>
								</CardContent>
							</Card>
						</Box>
					) : null}
				</Grid>
				<Grid item md={8} xs={12}>
					<Box sx={{ width: '100%', typography: 'body1' }}>
						<Box mb={2}>
							{user.id ? <Shops user={user} /> : null}
						</Box>
						<Box>
							<Card>
								<CardContent>
									<Typography sx={{ fontSize: ".9rem", fontWeight: "bold" }} mb={2}>Googleアカウント連携</Typography>
									{/* <Divider /> */}
									{user.id ? (
										<Box sx={{ display: "flex", p: 2, alignItems: "center" }}>
											<Avatar sx={{ mr: 3 }} src={GoogleIcon} />
											<Box mr="auto">
												<Typography>Google</Typography>
												{user.google_account ? (
													<Typography variant="subtitle2"><span style={{ fontWeight: "bold" }}>{user.google_account?.name}</span>で連携中</Typography>
												) : (
													<Typography variant="subtitle2">未連携</Typography>
												)}
											</Box>
											<Box>
												{user.google_account ? (
													<Box>
														<Button size="small" variant="outlined" color="error" onClick={handle_remove_google_auth}>連携解除</Button>
														{/* <Typography sx={{fontSize:".8rem"}}>連携解除は担当営業にご連絡ください</Typography> */}
													</Box>
												) : (
													<Button variant="outlined" href={google_auth_url}>連携する</Button>
												)}
											</Box>
										</Box>
									) : null}
								</CardContent>
							</Card>
						</Box>
						{/* <TabContext value={currentTab}>
							<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
								<TabList onChange={handleTabChange}>
									<Tab label="店舗一覧" value="tab1" icon={<FavoriteIcon />} iconPosition="start" sx={{ minHeight: 48 }} />
									<Tab label="設定" value="tab2" icon={<FavoriteIcon />} iconPosition="start" sx={{ minHeight: 48 }} />
									<Tab label="アカウント連携" value="tab3" icon={<FavoriteIcon />} iconPosition="start" sx={{ minHeight: 48 }} />
								</TabList>
							</Box>
							<TabPanel value="tab1" sx={{ px: 0 }}>
								{user.id ? <Shops user={user} /> : null}
							</TabPanel>
							<TabPanel value="tab2" sx={{ px: 0 }}>
								<Card>
									<CardContent>なにかしらの設定が入る予定</CardContent>
								</Card>
							</TabPanel>
							<TabPanel value="tab3" sx={{ px: 0 }}>
								<Card>
									<CardContent>
										<Typography variant="h6" mb={2}>アカウント連携</Typography>
										<Box sx={{ display: "flex", p: 2, alignItems: "center" }}>
											<Avatar sx={{ mr: 3 }} src={GoogleIcon} />
											<Box mr="auto">
												<Typography>Google</Typography>
												{user.google_account ? (
													<Typography variant="subtitle2"><span style={{ fontWeight: "bold" }}>{user.google_account?.name}</span>で連携中</Typography>
												) : (
													<Typography variant="subtitle2">未連携</Typography>
												)}
											</Box>
											<Box>
												{user.google_account ? (
													<Button variant="outlined" color="error" onClick={_ => alert("できていいのか？")}>連携解除？</Button>
												) : (
													<Button variant="outlined" href={google_auth_url}>連携する</Button>
												)}
											</Box>
										</Box>
									</CardContent>
								</Card>
							</TabPanel>
						</TabContext> */}
					</Box>
				</Grid>
			</Grid>
		</>
	);
}
