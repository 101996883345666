import { useNickFetch } from "#app/hooks/useNickFetch";
import { User, Shop } from "#app/models";
import { Box, Button, Card, CardContent, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LocationImportDialog from "./LocationImportDialog";
import AddShopDialog from "./AddShopDialog";

type Props = {
	user: User,
}
type GetUserShopsResponse = {
	shops: Shop[],
}
export default function Shops(props: Props) {
	// ロケーション（インポート）ダイアログ開閉.
	const [addShopDialogOpen, setAddShopDialogOpen] = useState(false);
	const [shops, setShops] = useState<Shop[]>();
	const { nickFetch } = useNickFetch();

	const getsShopList = async () => {
		const res = await nickFetch("/get_user_shops/", {
			user_id: props.user.id,
		});
		if (!res.error) {
			// ok.
			const _shops = [];
			for (const _s of (res.data as GetUserShopsResponse).shops) {
				_shops.push( new Shop(_s) );
			}
			setShops(_shops);
		}
	}
	useEffect(() => {
		(async function () {
			await getsShopList();
		})();
	}, []);

	// インポート完了.
	const handleImportComplete = async () => {
		getsShopList();
	}

	const ShopListTable = (
		<TableContainer component={Paper}>
			<Table size="small" aria-label="simple table">
				<TableHead sx={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
					<TableRow>
						<TableCell>店舗名</TableCell>
						<TableCell align="right"></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{shops?.map((shop) => (
						<TableRow
							hover
							key={shop.id}
							sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
						>
							<TableCell component="th" scope="row">
								{shop.title}
								{shop.type ? "（"+shop.getTypeLabel()+"）" : ""}
							</TableCell>
							<TableCell align="right">
								<Button size="small" component={Link} sx={{whiteSpace:"nowrap"}} to={"/shops/" + shop.id} variant="outlined">管理ページ</Button>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);

	return (
		<>
			<Card>
				<CardContent>
					<Box sx={{ display: "flex" }}>
						<Typography mr="1rem" sx={{ fontSize: ".9rem", fontWeight: "bold" }}>店舗一覧</Typography>
						<Typography mr="auto" sx={{ fontSize: ".9rem" }}>店舗数 {shops ? shops.length : 0}/{props.user.max_shop_num}</Typography>
						<Button size="small" variant="outlined" onClick={() => { setAddShopDialogOpen(true) }}>店舗追加</Button>
					</Box>
					<AddShopDialog
						open={addShopDialogOpen}
						onClose={() => { setAddShopDialogOpen(false); }}
						onComplete={handleImportComplete}
						user={props.user}
						shop_count={shops ? shops.length : 0}
					/>
				</CardContent>
				{shops?.length ? ShopListTable : null}
			</Card>
		</>
	);
}
