import { useNickFetch } from "#app/hooks/useNickFetch";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material";
import { useState } from "react";

/**
 * 投稿を作成したり編集したり.
 * 
 */
const Input = styled('input')({
	display: 'none',
});
type Policy = {
	url: string;
	filename: string;
	origin_url: string,
	form: { [key: string]: any };
}
type Props = {
	shop_id: number,
	onComplete?: ((origin_url:string) => void),
	size_limit: number,
	btn_text?: string,
}
const S3Uploader = function (props: Props) {

	const [loading, setLoading] = useState<boolean>(false);
	const { nickFetch } = useNickFetch();

	/**
	 * ファイル選択された.
	 */
	const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files || e.target.files.length === 0) return;
		const file = e.target.files[0];
		if (props.size_limit && props.size_limit <= file.size) {
			return alert("ファイルサイズが大きすぎます");
		}
		setLoading(true);

		try {
			// ポリシー取得.
			const policy = await get_policy(file);
			// S3に投げる.
			await upload_to_s3(file, policy);
			// 画像情報をDBに保存.. しようと思ったけどいいか..
			// 完了！
			if ( props.onComplete ) props.onComplete(policy.origin_url);
		} catch (error) {
			//エラーは握りつぶす.
		}

		e.target.value = "";//また選択できるようにリセット.
		setLoading(false);
	}
	/**
	 * ポリシー取得.
	 */
	const get_policy = async (file: File): Promise<Policy> => {
		const res = await nickFetch("/s3/policy", {
			// サーバにこれからアップロードするファイルの情報を渡す。
			// size: file.size,  // ファイルの大きさ
			content_type: file.type,  // ファイルの形式
			directory: "shop/" + props.shop_id,
			file_type: "jpeg_png",
		});
		if (res.error) {
			throw new Error("エラー");
		}
		return res.data;
	}
	/**
	 * S3に投げる.
	 */
	const upload_to_s3 = async (file: File, policy: Policy): Promise<void> => {
		return new Promise((resolve, reject) => {
			var name, fd = new FormData();
			for (name in policy.form) if (policy.form.hasOwnProperty(name)) {
				fd.append(name, policy.form[name]);
			}
			fd.append('file', file); // ファイルも忘れずに添付する.
			// S3に送信
			var xhr = new XMLHttpRequest();
			if (xhr.upload) {
				// プログレス取得.
				// 表示はしてないけど..
				xhr.upload.addEventListener("progress", function (e) {
					// progre = parseInt(e.loaded / e.total * 10000) / 100;
					// console.log(progre + "%");
				});
			}
			xhr.onreadystatechange = function () {
				switch (xhr.readyState) {
					case 0:
						// 未初期化状態.
						// console.log( 'uninitialized!' );
						break;
					case 1: // データ送信中.
						// console.log( 'loading...' );
						break;
					case 2: // 応答待ち.
						// console.log( 'loaded.' );
						break;
					case 3: // データ受信中.
						// console.log( 'interactive... '+xhr.responseText.length+' bytes.' );
						break;
					case 4: // データ受信完了.
						if (xhr.status == 204) {
							resolve();
						} else {
							reject();
						}
						break;
				}
			};
			xhr.open('POST', policy.url, true);
			// xhr.setRequestHeader("x-amz-acl", "public-read");
			xhr.send(fd);
		});
	}

	return (
		<>
			<label>
				<Input
					accept="image/*"
					type="file"
					onChange={handleChange}
					disabled={loading}
				/>
				<LoadingButton
					variant="contained"
					component="span"
					loading={loading}
				>
					{props.btn_text}
				</LoadingButton>
			</label>
		</>
	);
}
S3Uploader.defaultProps = {
	size_limit: 1024 * 1024 * 5,//なんとなく5MB制限.
}
export default S3Uploader;
