import SyncStatusBar from "#app/components/SyncStatusBar";
import { useNickFetch } from "#app/hooks/useNickFetch";
import { useNotice } from "#app/hooks/useNotice";
import { useShopPageProps } from "#app/layouts/ShopLayout";
import { PostStatus, ShopSync } from "#app/models";
import dayjs from "#app/utils/custom_dayjs";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Card, CardContent, Divider, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";



export default function ShopSettingsSyncHotpepper() {
	const { shop } = useShopPageProps();
	const { nickFetch } = useNickFetch();
	const [validateErrors, setValidateErrors] = useState<{ [key: string]: string }>({});
	const [hpb_salon_id, setHpbSalonId] = useState<string>("");
	const [saving, setSaving] = useState<number | null>(null);
	const { addNotice } = useNotice();
	const [blog_sync, setBlogSync] = useState<ShopSync>(new ShopSync);
	const [coupon_sync, setCouponSync] = useState<ShopSync>(new ShopSync);
	const [style_sync, setStyleSync] = useState<ShopSync>(new ShopSync);

	useEffect(() => {
		(async () => {
			// console.log(shop.sync_settings);
			setHpbSalonId(shop.hpb_salon_id);

			const res = await nickFetch("/get_shop_syncs/", {
				shop_id: shop.id,
			});
			if (!res.error) {
				// ok.
				// console.log(res);
				setBlogSync(new ShopSync(res.data.blog_sync));
				setCouponSync(new ShopSync(res.data.coupon_sync));
				setStyleSync(new ShopSync(res.data.style_sync));
				// setShops((res.data as GetUserShopsResponse).shops);
			}
		})();
	}, []);

	const handleChangeHpbSalonId = (e: any) => {
		shop.hpb_salon_id = e.target.value;
		setHpbSalonId(shop.hpb_salon_id);
	}

	/**
	 * 保存ボタン押された.
	 */
	const handleSave = async function () {
		/**
		 * 適当にバリデート.
		 */
		setSaving(99);
		const valierr: (typeof validateErrors) = {};
		if (shop.hpb_salon_id && shop.hpb_salon_id[0] !== "H") {
			valierr["hpb_salon_id"] = "『H』から始まる必要があります";
		}
		if (0 < Object.keys(valierr).length) {
			// setSaving(false);
			return setValidateErrors(valierr);
		}
		/**
		 * API叩く.
		 */
		const body = {
			hpb_salon_id: shop.hpb_salon_id,
			shop_id: shop.id,
		}
		const res = await nickFetch("/save_shop_hpb_salon_id/", body);
		if (!res.error) {
			// ok.
			setValidateErrors({});
			addNotice("保存しました", "success");
		}
		setSaving(null);
	}

	/**
	 * ShopSyncアイテムの保存ボタンが押された.
	 */
	const handleSyncSave = async function (sync: ShopSync) {
		setSaving(sync.source_platform);
		/**
		 * API叩く.
		 */
		const body = {
			shop_id: shop.id,
			sync: sync,
		}
		const res = await nickFetch("/save_shop_sync/", body);
		if (!res.error) {
			// ok.
			setValidateErrors({});
			addNotice("保存しました", "success");
		}
		setSaving(null);
	}


	return (
		<>
			<Box>
				<Typography mt={2} sx={{ fontSize: ".9rem", fontWeight: "bold" }}>ホットペッパー連携</Typography>
				<Grid container spacing={2} mt={2} mb={2}>
					<Grid item xs={12} md={3} display="table">
						<Typography display="table-cell" sx={{ "verticalAlign": "middle" }}>サロンID</Typography>
					</Grid>
					<Grid item md={6}>
						<TextField
							margin="dense"//or normal
							type="text"
							autoComplete="off"
							label="サロンID"
							placeholder="H000000000"
							onChange={handleChangeHpbSalonId}
							value={hpb_salon_id ? hpb_salon_id : ""}
							error={"hpb_salon_id" in validateErrors}
							helperText={validateErrors.hpb_salon_id ? validateErrors.hpb_salon_id : "Hから始まる9桁の数字"}
						/>
					</Grid>
					<Grid item md={3}>
						<Box mt={2}>
							<LoadingButton
								variant="outlined"
								onClick={handleSave}
								loading={saving == 99}
							>保存</LoadingButton>
						</Box>
					</Grid>
				</Grid>

				<Divider />
				<br />

				<Grid container spacing={2}>
					{/**
					 * ブログ取り込み設定.
					 */}
					<Grid item xl={3} lg={4} md={6} xs={12}>
						<Card sx={{ position: "relative" }}>
							<Box sx={{ px: 1, py: 0.5, backgroundColor: "rgba(0,0,0,0.03)", display: "flex" }}>
								{/* <Avatar variant="square" sx={{ mr: 1, width: 24, height: 24 }} src={InstagramIcon} /> */}
								<Typography sx={{ fontWeight: "bold" }}>
									ブログ取り込み設定
								</Typography>
							</Box>
							<CardContent>
								<Box sx={{ py: 1 }}>
									<FormControl>
										<FormLabel>ステータス</FormLabel>
										<SyncStatusBar sync={blog_sync} />
									</FormControl>
								</Box>
								<Box sx={{ py: 1 }}>
									<FormControl>
										<FormLabel>取り込み</FormLabel>
										<RadioGroup
											row
											onChange={(e) => {
												blog_sync.enabled = Boolean(parseInt(e.target.value));
												setBlogSync(Object.assign({}, blog_sync));
											}}
											value={blog_sync.enabled ? "1" : "0"} >
											<FormControlLabel control={<Radio value="0" />} label="無効" />
											<FormControlLabel control={<Radio value="1" />} label="有効" />
										</RadioGroup>
									</FormControl>
								</Box>
								<Box sx={{ py: 1 }}>
									<FormControl>
										<FormLabel>投稿設定</FormLabel>
										<RadioGroup
											row
											onChange={(e) => {
												blog_sync.post_status = parseInt(e.target.value) as PostStatus;
												setBlogSync(Object.assign({}, blog_sync));
											}}
											value={blog_sync.post_status} >
											<FormControlLabel control={<Radio value={PostStatus.DRAFT} />} label="下書きとして保存" />
											<FormControlLabel control={<Radio value={PostStatus.POSTING_WAIT} />} label="自動投稿" />
										</RadioGroup>
									</FormControl>
								</Box>
								{/* <Box sx={{ py: 1 }}>
									<TextField
										InputLabelProps={{ shrink: true }}//にゅっとした状態を強制.
										margin="normal"
										type="date"
										autoComplete="off"
										label="取り込み開始日"
										name="start_date"
										onChange={(e) => {
											blog_sync.start_date = e.target.value;
											setBlogSync(Object.assign({}, blog_sync));
										}}
										value={dayjs(blog_sync.start_date).isValid() ? dayjs(blog_sync.start_date).format("YYYY-MM-DD") : ""}
									/>
								</Box> */}
								<LoadingButton
									variant="outlined"
									onClick={() => { handleSyncSave(blog_sync) }}
									loading={saving == blog_sync.source_platform}
								>保存</LoadingButton>
							</CardContent>
						</Card>
					</Grid>

					{/**
					 * クーポン取り込み設定.
					 */}
					<Grid item xl={3} lg={4} md={6} xs={12}>
						<Card sx={{ position: "relative" }}>
							<Box sx={{ px: 1, py: 0.5, backgroundColor: "rgba(0,0,0,0.03)", display: "flex" }}>
								{/* <Avatar variant="square" sx={{ mr: 1, width: 24, height: 24 }} src={InstagramIcon} /> */}
								<Typography sx={{ fontWeight: "bold" }}>
									クーポン取り込み設定
								</Typography>
							</Box>
							<CardContent>
								<Box sx={{ py: 1 }}>
									<FormControl>
										<FormLabel>ステータス</FormLabel>
										<SyncStatusBar sync={coupon_sync} />
									</FormControl>
								</Box>
								<Box sx={{ py: 1 }}>
									<FormControl>
										<FormLabel>取り込み</FormLabel>
										<RadioGroup
											row
											onChange={(e) => {
												coupon_sync.enabled = Boolean(parseInt(e.target.value));
												setCouponSync(Object.assign({}, coupon_sync));
											}}
											value={coupon_sync.enabled ? "1" : "0"} >
											<FormControlLabel control={<Radio value="0" />} label="無効" />
											<FormControlLabel control={<Radio value="1" />} label="有効" />
										</RadioGroup>
									</FormControl>
								</Box>
								<Box sx={{ py: 1 }}>
									<FormControl>
										<FormLabel>投稿設定</FormLabel>
										<RadioGroup
											row
											onChange={(e) => {
												coupon_sync.post_status = parseInt(e.target.value) as PostStatus;
												setCouponSync(Object.assign({}, coupon_sync));
											}}
											value={coupon_sync.post_status} >
											<FormControlLabel control={<Radio value={PostStatus.DRAFT} />} label="下書きとして保存" />
											<FormControlLabel control={<Radio value={PostStatus.POSTING_WAIT} />} label="自動投稿" />
										</RadioGroup>
									</FormControl>
								</Box>
								<LoadingButton
									variant="outlined"
									onClick={() => { handleSyncSave(coupon_sync) }}
									loading={saving == coupon_sync.source_platform}
								>保存</LoadingButton>
							</CardContent>
						</Card>
					</Grid>

					{/**
					 * スタイル取り込み設定.
					 */}
					<Grid item xl={3} lg={4} md={6} xs={12}>
						<Card sx={{ position: "relative" }}>
							<Box sx={{ px: 1, py: 0.5, backgroundColor: "rgba(0,0,0,0.03)", display: "flex" }}>
								{/* <Avatar variant="square" sx={{ mr: 1, width: 24, height: 24 }} src={InstagramIcon} /> */}
								<Typography sx={{ fontWeight: "bold" }}>
									スタイル取り込み設定
								</Typography>
							</Box>
							<CardContent>
								<Box sx={{ py: 1 }}>
									<FormControl>
										<FormLabel>ステータス</FormLabel>
										<SyncStatusBar sync={style_sync} />
									</FormControl>
								</Box>
								<Box sx={{ py: 1 }}>
									<FormControl>
										<FormLabel>取り込み</FormLabel>
										<RadioGroup
											row
											onChange={(e) => {
												style_sync.enabled = Boolean(parseInt(e.target.value));
												setStyleSync(Object.assign({}, style_sync));
											}}
											value={style_sync.enabled ? "1" : "0"} >
											<FormControlLabel control={<Radio value="0" />} label="無効" />
											<FormControlLabel control={<Radio value="1" />} label="有効" />
										</RadioGroup>
									</FormControl>
								</Box>
								<Box sx={{ py: 1 }}>
									<FormControl>
										<FormLabel>投稿設定</FormLabel>
										<RadioGroup
											row
											onChange={(e) => {
												style_sync.post_status = parseInt(e.target.value) as PostStatus;
												setStyleSync(Object.assign({}, style_sync));
											}}
											value={style_sync.post_status} >
											<FormControlLabel control={<Radio value={PostStatus.DRAFT} />} label="下書きとして保存" />
											<FormControlLabel control={<Radio value={PostStatus.POSTING_WAIT} />} label="自動投稿" />
										</RadioGroup>
									</FormControl>
								</Box>
								<LoadingButton
									variant="outlined"
									onClick={() => { handleSyncSave(style_sync) }}
									loading={saving == style_sync.source_platform}
								>保存</LoadingButton>
							</CardContent>
						</Card>
					</Grid>
				</Grid>

			</Box>
		</>
	);
}
