import { Box, Button, Typography, DialogContent, DialogActions, List, ListItem, ListItemAvatar, Avatar, ListItemText, DialogProps, CircularProgress, ListSubheader, Select, FormControl, InputLabel, MenuItem, DialogContentText } from "@mui/material";
import { useNickFetch } from "#app/hooks/useNickFetch";
import { useState } from "react";
import { useNotice } from "#app/hooks/useNotice";
import {User } from "#app/models";
import NickDialog from "#app/components/NickDialog";
import LoadingButton from "@mui/lab/LoadingButton";
import { sleep } from "#app/utils";

/**
 * components 側に置いたほうがいいのかもしれない.
 * 別の場所で使う予定もないし users/Detail/ に置いてる.
 */

type Props = {
	open: boolean,
	user: User,
	onComplete?: (() => void),
}


export default function BuyShopMaxDialog(props: Props & DialogProps) {
	const { nickFetch } = useNickFetch();
	const [loading, setLoading] = useState<boolean>(false);
	const [buyAmount, setBuyAmount] = useState<number>(1);
	const [buyLoading, setBuyLoading] = useState<boolean>(false);
	const [buyComplete, setBuyComplete] = useState<boolean>(false);
	const [initial_cost, setInitial_cost] = useState<number>(0);
	const [monthly_cost, setMonthly_cost] = useState<number>(0);
	const [first_month_cost, setFirst_month_cost] = useState<number>(0);
	
	/**
	 * 開いた.
	 */
	const handleOpen = function () {
		(async () => {
			setBuyComplete(false);//初期化.
			setLoading(true);
			const res = await nickFetch("/get_shop_price/", {
				user_id: props.user.id,
			});
			if (!res.error) {
				// ok.
				setInitial_cost(res.data.initial_cost as number );
				setMonthly_cost(res.data.monthly_cost as number);
				setFirst_month_cost(res.data.first_month_cost as number);
			} else {
				handleEditClose();
			}
			setLoading(false);
		})();
	}
	/**
	 * 購入ボタン押した.
	 */
	const handleBuyClick = function () {
		(async () => {
			setBuyLoading(true);
			const res = await nickFetch("/buy_max_shop_num/", {
				user_id: props.user.id,
				buy_amount: buyAmount,
			});
			if (!res.error) {
				// ok.
				// const data = res.data as GetsGoogleLocationResponse;
				// console.log(res.data);
				if ( res.data.success ) {
					setBuyComplete(true);
				} else {

				}
			}
			setBuyLoading(false);
		})();
	}
	// 閉じる処理. 渡された関数実行するだけ.
	const handleEditClose = () => {
		if (props.onClose) props.onClose({}, "backdropClick");
	};

	return (
		<>
			<NickDialog
				open={props.open}
				onClose={props.onClose}
				fullWidth={true}
				maxWidth="sm"
				onOpen={handleOpen}
			>
				<Box>
					{buyComplete ? (
						<DialogContent>
							<Typography variant="body1" sx={{ textAlign: "center" }}>
								決済処理が完了しました<br />
								弊社で決済確認後、店舗追加可能になります<br />
								<br />
								<Button onClick={handleEditClose} variant="outlined" color="inherit">閉じる</Button>
							</Typography>
						</DialogContent>
					) : (
						<>
							{/* <DialogTitle>編集</DialogTitle> */}
							<Typography variant="h6" sx={{ textAlign: "center", pt: 3 }}>店舗上限を追加</Typography>
							{loading ? (
								<DialogContent
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										flexDirection: "column",
									}}
								>
									<CircularProgress />
									<Typography sx={{ mt: 1.5 }} variant="caption">読み込み中</Typography>
								</DialogContent>
							) : (
								<>
									<DialogContent>
										<DialogContentText variant="body2" sx={{ textAlign: "center", mb: 2 }}>
											追加したい店舗数を選択してください
										</DialogContentText>
										<h4 style={{background:"#ca7745",color:"#fff",padding:".5rem"}}>購入数（追加店舗数）</h4>
										<FormControl sx={{marginBottom:1}} fullWidth>
											<InputLabel id="buyNum-select-label">購入数</InputLabel>
											<Select
												disabled={buyLoading}
												labelId="buyNum-select-label"
												id="buyNum-select"
												value={buyAmount}
												label="購入数"
												onChange={(e)=>{setBuyAmount(parseInt(e.target.value as string))}}
											>
												<MenuItem value={1}>1</MenuItem>
												<MenuItem value={2}>2</MenuItem>
												<MenuItem value={3}>3</MenuItem>
												<MenuItem value={4}>4</MenuItem>
												<MenuItem value={5}>5</MenuItem>
												<MenuItem value={6}>6</MenuItem>
												<MenuItem value={7}>7</MenuItem>
												<MenuItem value={8}>8</MenuItem>
												<MenuItem value={9}>9</MenuItem>
												<MenuItem value={10}>10</MenuItem>
											</Select>
										</FormControl>
										<h4 style={{background:"#ca7745",color:"#fff",padding:".5rem"}}>サブスクリプション内容</h4>
										<Box mb={3}>
											<p>
												<b>月額費用（1店舗あたり）</b><br />
												{monthly_cost.toLocaleString()}円（税別）
											</p>
										</Box>
										<h4 style={{background:"#ca7745",color:"#fff",padding:".5rem"}}>お支払内容</h4>
										<Box>
											<p>
												<b>初期費用（1店舗あたり）</b><br />
												{initial_cost.toLocaleString()}円（税別）
											</p>
											<p>
												<b>初月ご利用料金（1店舗あたり）</b><br />
												{first_month_cost.toLocaleString()}円（税別）
											</p>
										</Box>
										<hr style={{margin:"1rem 0"}} />
										<div>
											個数　：{buyAmount}店舗<br />
											小計　：{(buyAmount * (initial_cost+first_month_cost) ).toLocaleString()}円<br />
											消費税：{Math.floor(buyAmount * (initial_cost+first_month_cost) * 0.1).toLocaleString()}円<br />
											合計　：{Math.floor(buyAmount * (initial_cost+first_month_cost) * 1.1).toLocaleString()}円<br />
										</div>
										<hr style={{margin:"1rem 0"}} />
										<div style={{textAlign:"center"}}>
											<LoadingButton
												loading={buyLoading}
												onClick={handleBuyClick}
												variant="contained"
												sx={{paddingX:5}}
											>購入する</LoadingButton>
										</div>
									</DialogContent>
									{/* <DialogActions sx={{ p: 2 }}>
										<Button color="inherit" onClick={handleEditClose} sx={{ marginRight: "6px" }}>閉じる</Button>
									</DialogActions> */}
								</>
							)}
						</>
					)}
				</Box>
			</NickDialog>
		</>
	);
}
