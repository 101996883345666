/**
 * Recoilのstate達.
 */
import { User } from "#app/models";
import { atom } from "recoil";

export const darkModeState = atom<boolean>({
	key: "darkMode",
	default: false,
});
//
export const didInitState = atom<boolean>({
	key: "didInit",
	default: false,
});
//
export const viewerState = atom<User>({
	key: "viewer",
	default: new User(),//viewerの名前とか表示する時にnullの可能性あるとメンドイのでnull入らないように.
});
//
export const drawerOpenState = atom<boolean>({
	key: "drawerOpen",
	default: false,
});
