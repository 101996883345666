import { Dialog, DialogProps } from "@mui/material";
import { useEffect } from "react";

/**
 * MUIのダイアログに onOpen が無かったのでできるようにした.
 */
type NickDialogProps = DialogProps & {
	onOpen?: () => void,
}
let prev_open = false;//こうすれば開いた時に何かすることができるぞ. キモいけど.

export default function NickDialog({ onOpen, ...props }: NickDialogProps) {
	useEffect(() => {
		// console.log("props.open = " + props.open);
		if ( prev_open && !props.open ) {
			prev_open = false;
			// console.log("閉じた！");
		}
		if (!prev_open && props.open) {
			// 開いた！
			prev_open = true;
			if (onOpen) onOpen();
			// console.log("開いた!");
		}
		// ここから関数を返すと、Reactはアンマウントの直前にそれを呼び出す.
		return () => {
			// console.log("unmount! ");
		}
	});
	return (
		<Dialog {...props as DialogProps} />
	);
}
