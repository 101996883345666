import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Home from '#app/pages/Home';
import Login from '#app/pages/Login';
import NotFound from '#app/pages/NotFound';
import Init from '#app/pages/Init';
import ScrollTop from '#app/components/ScrollTop';
import { useViewer } from '#app/hooks/useViewer';
import MainLayout from '#app/layouts/MainLayout';
import ShopLayout from '#app/layouts/ShopLayout';
import Users from '#app/pages/Users';
import Shops from '#app/pages/Shops/Index';
import ShopDetail from '#app/pages/Shops/Detail';
import ShopPostsGoogle from '#app/pages/Shops/Posts/Google';
import ShopPosts from '#app/pages/Shops/Posts';
import ShopPhotos from '#app/pages/Shops/Photos';
import ShopPerformance from '#app/pages/Shops/Performance';
import UserDetail from '#app/pages/Users/Detail';
import ShopSettingsSyncHotpepper from '#app/pages/Shops/Settings/Sync/Hotpepper';
import ShopSettingsSyncInstagram from '#app/pages/Shops/Settings/Sync/Instagram';


/**
 * ログインしてないと見れない.
 */
const PrivateChecker = ({ ...props }) => {
	const { viewer } = useViewer();
	const isAuthenticated = viewer.id !== 0; //認証されているかの判定.
	if (isAuthenticated) {
		// return <Route {...props}/>
		return props.to;
	} else {
		return <Navigate to="/login" />
	}
}
/**
 * ログインしてたら見れない.
 */
const NoPrivateChecker = ({ ...props }) => {
	const { viewer } = useViewer();
	const isAuthenticated = viewer.id !== 0; //認証されているかの判定.
	if (!isAuthenticated) {
		// return <Route {...props}/>
		return props.to;
	} else {
		return <Navigate to="/" />
	}
}

export default function AppRouter() {
	return (
		<BrowserRouter>
			<Init>
				<ScrollTop />
				<Routes>
					<Route path="/" element={<PrivateChecker to={<MainLayout />} />}>
						<Route path="/" element={<Home />} />
						<Route path="/users">
							<Route path="" element={<Users />} />
							<Route path=":user_id" element={<UserDetail />} />
						</Route>
						<Route path="/shops" element={<Shops />} />
						<Route path="/shops" element={<ShopLayout />}>
							<Route path=":shop_id" element={<ShopDetail />} />
							<Route path=":shop_id/posts" element={<ShopPosts />} />
							<Route path=":shop_id/posts/google" element={<ShopPostsGoogle />} />
							<Route path=":shop_id/photos" element={<ShopPhotos />} />
							<Route path=":shop_id/performance" element={<ShopPerformance />} />
							<Route path=":shop_id/settings/sync/hotpepper" element={<ShopSettingsSyncHotpepper />} />
							<Route path=":shop_id/settings/sync/instagram" element={<ShopSettingsSyncInstagram />} />
						</Route>
					</Route>
					<Route path="/login" element={<NoPrivateChecker to={<Login />} />} />
					<Route path="*" element={<NotFound />} />
				</Routes>
			</Init>
		</BrowserRouter>
	);
}

