import { useShopPageProps } from "#app/layouts/ShopLayout";
import { Box, Button, Card, CardContent, FormControl, FormLabel, Grid, Typography } from "@mui/material";

export default function ShopDetail() {
	const { shop, reloadShop } = useShopPageProps();

	return (
		<div>
			<Typography mt={2} mb={3} sx={{ fontSize: ".9rem", fontWeight: "bold" }}>ダッシュボード</Typography>
			
			<Grid container spacing={2}>
					{/**
					 * ブログ取り込み設定.
					 */}
					<Grid item xl={3} lg={4} md={6} xs={12}>
						<Card sx={{ position: "relative" }}>
							<Box sx={{ px: 1, py: 0.5, backgroundColor: "rgba(0,0,0,0.03)", display: "flex" }}>
								{/* <Avatar variant="square" sx={{ mr: 1, width: 24, height: 24 }} src={InstagramIcon} /> */}
								<Typography sx={{ fontSize: ".9rem", fontWeight: "bold" }}>
									お知らせ
								</Typography>
							</Box>
							<CardContent>
								<Box sx={{ py: 1 }}>
									<Typography>お知らせはありません</Typography>
								</Box>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
		</div>
	);
}
