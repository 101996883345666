import { useViewer } from '#app/hooks/useViewer';
import { Outlet } from 'react-router-dom';
import Header from "./Header";
import { Box } from '@mui/material';

export default function AppDashboard() {
	const { viewer, setViewer } = useViewer();
	const headerHeight = viewer.isMaster() ? 88 : 48;
	return (
		<div>
			<Header />
			<Box sx={{marginTop:headerHeight+"px",padding:3}}>
				<Outlet />{/** ここに子ページの内容が入る */}
			</Box>
		</div>
	);
}
