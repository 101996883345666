import { Box, Button, Typography, DialogContent, DialogActions, List, ListItem, ListItemAvatar, Avatar, ListItemText, DialogProps, CircularProgress, ListSubheader } from "@mui/material";
import { useEffect, useState } from "react";
import {User } from "#app/models";
import LocationImportDialog from "./LocationImportDialog";
import BuyShopMaxDialog from "./BuyShopMaxDialog";
import { useUserGetter } from "#app/hooks/useUserGetter";
import { useNickFetch } from "#app/hooks/useNickFetch";

/**
 * ビジネスプロフィールからインポートさせるか課金させるか.
 */

type Props = {
	open: boolean,
	user: User,
	shop_count: number,
	onComplete?: (() => void),
}
export default function AddShopDialog(props: Props & DialogProps) {
	const { user, refleshUser } = useUserGetter(props.user.id);

	return (
		<>
			{user.max_shop_num <= props.shop_count ? (
				<>
					<BuyShopMaxDialog
						open={props.open}
						onClose={props.onClose}
						onComplete={props.onComplete}
						user={props.user}
					/>
				</>
			) : (
				<>
					<LocationImportDialog
						open={props.open}
						onClose={props.onClose}
						onComplete={props.onComplete}
						user={props.user}
					/>
				</>
			)}
		</>
	);
}