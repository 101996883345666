/**
 * ホストで判断もどうかと思うが、、本番開発のフラグ.
 */
let is_develop = false;
if ( -1 < window.location.host.indexOf("localhost") ) {
	is_develop = true;
} else if ( -1 < window.location.host.indexOf("dashboard.business.nickworks") ) {
	is_develop = true;
}
export const IS_DEVELOP = is_develop;

export const SERVER_HOST = IS_DEVELOP ? "business.nickworks.net" : "hicheez.jp";
export const SERVER_SCHEME = IS_DEVELOP ? "https" : "https";
