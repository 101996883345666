import { useNickFetch } from "#app/hooks/useNickFetch";
import { useShopPageProps } from "#app/layouts/ShopLayout";
import { LocalPost } from "#app/models/LocalPost";
import LocalPostDialog from "#app/pages/Shops/LocalPostDialog";
import dayjs from "#app/utils/custom_dayjs";
import NoImage from "#assets/no-image.svg";
import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, Chip, Grid, IconButton, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';


type GetGooglePostsResponse = {
	localPosts: LocalPost[];
	nextPageToken: string,
}
export default function ShopPostsGoogle() {
	const { shop } = useShopPageProps();
	const [localPosts, setLocalPosts] = useState<LocalPost[]>([]);
	const [dialogTargetPost, setDialogTargetPost] = useState<LocalPost | null>(null);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [menuTargetPost, setMenuTargetPost] = useState<LocalPost | null>(null);
	const { nickFetch } = useNickFetch();

	// const _posts = [
	// 	new LocalPost(JSON.parse(`{"alertType":null,"createTime":"2021-04-23T10:11:31.880Z","languageCode":"ja","name":"accounts/106048588091978471951/locations/18071518475996676230/localPosts/3401584999070961514","searchUrl":"https://local.google.com/place?id=10059175379854703175&use=posts&lpsid=CIHM0ogKEICAgICKj-6k1AE","state":"LIVE","summary":null,"topicType":"STANDARD","updateTime":"2021-04-23T10:11:31.880Z","media":[{"createTime":null,"description":null,"googleUrl":"https://lh3.googleusercontent.com/p/AF1QipNj5_Zl5O6SQgwWZLkZwgVMx6-_2vbId9LIIomr","mediaFormat":"PHOTO","name":"accounts/106048588091978471951/locations/18071518475996676230/media/localPosts/AF1QipNj5_Zl5O6SQgwWZLkZwgVMx6-_2vbId9LIIomr","sourceUrl":null,"thumbnailUrl":null}]}`)),
	// 	new LocalPost(JSON.parse(`{"alertType":null,"createTime":"2022-05-20T04:01:15.869Z","languageCode":"ja","name":"accounts/100620725616122112083/locations/13424138955836643683/localPosts/3482335008361790682","searchUrl":null,"state":"LIVE","summary":"\u72ac\u3067\u3059","topicType":"STANDARD","updateTime":"2022-05-20T04:01:15.869Z","callToAction":{"actionType":"LEARN_MORE","url":"https://www.pakutaso.com/photo/78980.html"},"media":[{"createTime":null,"description":null,"googleUrl":"https://lh3.googleusercontent.com/p/AF1QipMGiHziUt5H1P3-9FneEcHdEkaNf6ah8iSHFVEx","mediaFormat":"PHOTO","name":"accounts/100620725616122112083/locations/13424138955836643683/media/localPosts/AF1QipMGiHziUt5H1P3-9FneEcHdEkaNf6ah8iSHFVEx","sourceUrl":null,"thumbnailUrl":null}]}`)),
	// 	new LocalPost(JSON.parse(`{"alertType":null,"createTime":"2022-05-20T05:43:15.609Z","languageCode":"ja","name":"accounts/100620725616122112083/locations/13424138955836643683/localPosts/1459859136243480566","searchUrl":null,"state":"LIVE","summary":"\u5199\u771f\u306a\u3057","topicType":"STANDARD","updateTime":"2022-05-20T05:43:15.609Z"}`)),
	// ];
	useEffect(() => {
		(async function () {
			const res = await nickFetch("/get_google_posts/", {
				shop_id: shop.id,
			});
			const data = res.data as GetGooglePostsResponse;
			if (!res.error && data.localPosts) {
				// ok.
				const tmp = [];
				for (const localPost of data.localPosts) {
					tmp.push(new LocalPost(localPost));
				}
				setLocalPosts(tmp);
			}
		})();
	}, []);

	const handleMenuClose = () => {
		setAnchorEl(null);
		setMenuTargetPost(null);
	}

	return (
		<>
			<Typography my={2}>Googleに投稿されている投稿一覧を表示. このページいらないかも.</Typography>
			<Grid container spacing={2}>
				{localPosts.map((localPost, index) => (
					<Grid key={index} item xl={2} lg={3} md={4} xs={6}>
						<Card sx={{ position: "relative" }}>
							{/* <Box sx={{ display: "flex" }}>
								<Typography variant="body2" ml={2} mr="auto" sx={{ verticalAlign: "center",alignSelf:"center" }}>
									{post.createTime === post.updateTime ? (
										<>投稿: {dayjs(post.createTime).format("YYYY年M月D日(dd)")}</>
									) : (
										<>更新: {dayjs(post.updateTime).format("YYYY年M月D日(dd)")}</>
									)}
								</Typography>
								<IconButton aria-label="settings" sx={{mr:1}}>
									<MoreVertIcon />
								</IconButton>
							</Box> */}
							<CardActionArea onClick={() => { setDialogTargetPost(localPost) }}>
								<CardMedia
									component="img"
									height="220px"
									sx={{ backgroundColor: "#FFF" }}
									image={localPost.getMediaUrl() || NoImage}
									alt="画像"
								/>
								<Box sx={{ height: "80px", background: "linear-gradient(rgba(0,0,0,.54),transparent)", position: "absolute", top: 0, width: "100%" }}>
									<Typography variant="body2" color="#fff" ml={1} mt={1}>
										{localPost.createTime === localPost.updateTime ? (
											<>投稿: {dayjs(localPost.createTime).format("YYYY年M月D日(dd)")}</>
										) : (
											<>更新: {dayjs(localPost.updateTime).format("YYYY年M月D日(dd)")}</>
										)}
									</Typography>
								</Box>
								<CardContent sx={{ pb: 0, pt: 1 }}>
									<Chip icon={localPost.getTopicTypeIcon()} label={localPost.getTopicTypeLabel()} size="small" variant="outlined" />
									{localPost.state !== "LIVE" ?
										<Chip label={localPost.getStateLabel()} sx={{ ml: 1 }} size="small" variant="outlined" color={localPost.getStateColor()} />
										: null}
								</CardContent>
								<CardContent sx={{ pb: 2, pt: 1 }}>
									<Typography variant="body2" sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
										{localPost.summary ? (localPost.summary) : (<Box component="span" color="text.secondary">本文なし</Box>)}
									</Typography>
								</CardContent>
							</CardActionArea>
							<CardActions sx={{ py: 0, backgroundColor: "rgba(0,0,0,0.03)" }}>
								<IconButton
									aria-label="settings" sx={{ ml: "auto" }}
									onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
										setAnchorEl(event.currentTarget);
										setMenuTargetPost(localPost);
									}}
								>
									<MoreVertIcon />
								</IconButton>
							</CardActions>
						</Card>
					</Grid>
				))}
			</Grid>
			{menuTargetPost !== null ? (
				<Menu
					keepMounted
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
					transformOrigin={{ vertical: 'top', horizontal: 'right' }}
					onClose={handleMenuClose}
				>
					<MenuItem onClick={() => { setDialogTargetPost(menuTargetPost); handleMenuClose(); }}>
						<ListItemText>詳細</ListItemText>
					</MenuItem>
					{menuTargetPost.searchUrl ? (
						<MenuItem component="a" href={menuTargetPost.searchUrl} target="_blank" onClick={handleMenuClose}>
							<ListItemText>検索で投稿を表示</ListItemText>
						</MenuItem>
					) : null}
				</Menu>
			) : null}
			<LocalPostDialog
				open={dialogTargetPost !== null}
				onClose={() => { setDialogTargetPost(null) }}
				localPost={dialogTargetPost}
			/>
		</>
	);
}
