import { Box, Divider, Grid, Button, Typography, Dialog, DialogContent, DialogContentText, DialogActions, TextField } from "@mui/material";
import { useParams } from "react-router";
import { useNickFetch } from "#app/hooks/useNickFetch";
import { useState } from "react";
import { useNotice } from "#app/hooks/useNotice";
import { User } from "#app/models";

/**
 * components 側に置いたほうがいいのかもしれない.
 * 別の場所で使う予定もないし users/Detail/ に置いてる.
 * 
 * ダイアログ開いた時にユーザー取り直したい場合・・・
 *   取り直し自体は外で開く時に refleshUser 叩けば取れるが、
 *   defaultValueに入れる形だとマウント時の値が使われるため更新されない.
 *   valueに入れれば更新されるが書き換えできない. onChangeも併用して使えばどうにかできるかもだけどpropsの値だから変えられんくない？
 *     https://mui.com/material-ui/react-text-field/#uncontrolled-vs-controlled
 *   まあメンドイのでスルー.
 */

type EditDialogProps = {
	open: boolean,
	user: User,
	onClose?: (() => void),//?を付けると任意になるんだって！
	onComplete?: (() => void),
}
export default function EditDialog(props: EditDialogProps) {
	const params = useParams();
	const user_id = Number(params.user_id);
	const user = props.user;
	const { addNotice } = useNotice();
	const { nickFetch } = useNickFetch();

	// 閉じる処理. 渡された関数実行するだけ.
	const handleEditClose = () => {
		if (props.onClose) props.onClose();
	};

	/**
	 * 編集サブミット.
	 */
	const [validateErrors, setValidateErrors] = useState<{ [key: string]: string }>({});
	const handleSubmit = async function (e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		const formData = new FormData(e.currentTarget);
		/**
		 * API叩く.
		 */
		const res = await nickFetch("/set_user/", {
			user_id: user_id,
			login_id: formData.get("login_id"),
			name: formData.get("name"),
			email: formData.get("email"),
		});
		if (!res.error) {
			if (res.data && res.data.validate_errors) {
				// バリデートエラー.
				setValidateErrors(res.data.validate_errors);
			} else {
				// ok.
				if (props.onComplete) props.onComplete(); // 親側でユーザー取り直し.
				setValidateErrors({});
				handleEditClose();
				addNotice("保存しました", "success");
			}
		}
	};
	return (
		<>
			<Dialog
				open={props.open}
				onClose={handleEditClose}
				fullWidth={true}
				maxWidth="sm"
			>
				<Box component="form" noValidate onSubmit={handleSubmit}>
					{/* <DialogTitle>編集</DialogTitle> */}
					<Typography variant="h6" sx={{ textAlign: "center", pt: 3 }}>編集</Typography>
					<DialogContent>
						<DialogContentText variant="body2" sx={{ textAlign: "center", mb: 2 }}>
							編集し保存ボタンを押して下さい
						</DialogContentText>
						<Grid container spacing={2}>
							<Grid item md={6} xs={12}>
								<TextField
									fullWidth
									margin="dense"//or normal
									type="text"
									autoComplete="off"
									label="ログインID"
									name="login_id"
									defaultValue={user.login_id}
									error={"login_id" in validateErrors}
									helperText={validateErrors.login_id ? validateErrors.login_id : null}
								/>
							</Grid>
							<Grid item md={6} xs={12} sx={{ display: { xs: "none", md: "block" } }}></Grid>
							<Grid item md={6} xs={12}>
								<TextField
									fullWidth
									margin="dense"//or normal
									type="text"
									autoComplete="off"
									label="名前"
									name="name"
									defaultValue={user.name}
									error={"name" in validateErrors}
									helperText={validateErrors.name ? validateErrors.name : null}
								/>
							</Grid>
							<Grid item md={6} xs={12}>
								<TextField
									fullWidth
									margin="dense"//or normal
									type="email"
									label="メールアドレス"
									name="email"
									defaultValue={user.email}
									error={"email" in validateErrors}
									helperText={validateErrors.email ? validateErrors.email : null}
								/>
							</Grid>
						</Grid>
					</DialogContent>
					<Divider />
					<DialogActions sx={{ p: 2 }}>
						<Button color="inherit" onClick={handleEditClose} sx={{ marginRight: "6px" }}>キャンセル</Button>
						<Button variant="contained" type="submit">保存</Button>
					</DialogActions>
				</Box>
			</Dialog>
		</>
	);
}
