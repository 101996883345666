import { useShopPageProps } from "#app/layouts/ShopLayout";
import { Box, Button, Card, CardContent, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNickFetch } from "#app/hooks/useNickFetch";
import { LineChart, PieChart } from "@mui/x-charts";


type ChartData = {
	date : Date;
	website_click: number;
	booking: number;
	total: number;
	direction_request: number;
	food_order : number;
	BUSINESS_IMPRESSIONS_DESKTOP_MAPS: number;
	BUSINESS_IMPRESSIONS_DESKTOP_SEARCH: number;
	BUSINESS_IMPRESSIONS_MOBILE_MAPS: number;
	BUSINESS_IMPRESSIONS_MOBILE_SEARCH: number;
	BUSINESS_CONVERSATIONS: number;
	CALL_CLICKS: number;
}
type ChartSearchData = {
	date : Date;
	search_count: number;
}
class ImpressionTotalDict {
	BUSINESS_IMPRESSIONS_DESKTOP_MAPS: number = 0;
	BUSINESS_IMPRESSIONS_DESKTOP_SEARCH: number = 0;
	BUSINESS_IMPRESSIONS_MOBILE_MAPS: number = 0;
	BUSINESS_IMPRESSIONS_MOBILE_SEARCH: number = 0;
	reset () {
		this.BUSINESS_IMPRESSIONS_DESKTOP_MAPS = 0;
		this.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH = 0;
		this.BUSINESS_IMPRESSIONS_MOBILE_MAPS = 0;
		this.BUSINESS_IMPRESSIONS_MOBILE_SEARCH = 0;
	}
}
const keyToLabel: { [key: string]: string } = {
	website_click: 'ウェブサイトのクリック数',
	direction_request: 'ルート検索',
	booking: '予約',
	food_order: '料理の注文数',
	total: '合計',
};
type SearchKeyword = {
	search_keyword: number;
	value: number | null;
	threshold: number | null;
}

export default function ShopPerformance() {
	const { shop, reloadShop } = useShopPageProps();
	const [loading, setLoading] = useState<boolean>(false);
	const [interactionTotal, setInteractionTotal] = useState<number>(0);
	const [chartDataList, setChartDataList] = useState<ChartData[]>([]);
	const [searchChartDataList, setChartSearchDataList] = useState<ChartSearchData[]>([]);
	const [impressionTotalDict, setImpressionTotalDict] = useState<ImpressionTotalDict>(new ImpressionTotalDict());
	const [searchKeywordTotal, setSearchKeywordTotal] = useState<number>(0);
	const [searchKeywordList, setSearchKeyword] = useState<SearchKeyword[]>([]);
	const { nickFetch } = useNickFetch();
	const [startDate, setStartDate] = useState<string>("");
	const [endDate, setEndDate] = useState<string>("");

	const refleshPerformance = async () => {
		setLoading(true);
		const res = await nickFetch("/get_chart_datas/", {
			shop_id: shop.id,
			start_date : startDate,
			end_date : endDate,
		});
		const data = res.data;
		if (!res.error && data.chart_datas) {
			// ok.
			const tmp = [];
			let _interaction_total = 0;
			impressionTotalDict.reset();
			for (const d of data.chart_datas) {
				let total = d.wc + d.bk + d.dr + d.fo;
				_interaction_total += total;
				const data : ChartData = {
					date : new Date(d.year, d.month-1, d.day),
					website_click : d.wc,
					booking : d.bk,
					direction_request : d.dr,
					food_order : d.fo,
					total : total,
					BUSINESS_IMPRESSIONS_DESKTOP_MAPS	: d.dm,
					BUSINESS_IMPRESSIONS_DESKTOP_SEARCH	: d.ds,
					BUSINESS_IMPRESSIONS_MOBILE_MAPS	: d.mm,
					BUSINESS_IMPRESSIONS_MOBILE_SEARCH	: d.ms,
					BUSINESS_CONVERSATIONS		: d.bc,
					CALL_CLICKS	: d.cc,
				};
				tmp.push(data);
				impressionTotalDict.BUSINESS_IMPRESSIONS_DESKTOP_MAPS += data.BUSINESS_IMPRESSIONS_DESKTOP_MAPS;
				impressionTotalDict.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH += data.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH;
				impressionTotalDict.BUSINESS_IMPRESSIONS_MOBILE_MAPS += data.BUSINESS_IMPRESSIONS_MOBILE_MAPS;
				impressionTotalDict.BUSINESS_IMPRESSIONS_MOBILE_SEARCH += data.BUSINESS_IMPRESSIONS_MOBILE_SEARCH;
			}
			// 検索数の月別グラフも.
			const tmp2 = [];
			for (const d of data.chart_datas) {
				const data : ChartSearchData = {
					date : new Date(d.year, d.month-1, d.day),
					search_count	: d.search_count,
				};
				tmp2.push(data);
			}

			setStartDate( data.start_month );
			setEndDate( data.end_month );
			
			setInteractionTotal(_interaction_total);
			setChartDataList(tmp);
			setChartSearchDataList(tmp2);
			setImpressionTotalDict(impressionTotalDict);
		}
		if (!res.error && data.search_keywords) {
			const search_keywords:SearchKeyword[] = [];
			let total = 0;
			for (const d of data.search_keywords) {
				const value = d[1] ? parseInt(d[1]) : null
				if ( value ) total += value;
				search_keywords.push({
					search_keyword : d[0],
					value : value,
					threshold : d[2] ? parseInt(d[2]) : null,
				});
			}
			setSearchKeyword(search_keywords);
			setSearchKeywordTotal(total);
		}
		setLoading(false);
	}

	const handle_change_date = async ( e: React.ChangeEvent<HTMLInputElement> ) => {
		if ( e.target.name === "start_date" ) {
			setStartDate( e.target.value );
		}
		if ( e.target.name === "end_date" ) {
			setEndDate( e.target.value );
		}
	}
	const handle_date_submit = async () => {
		console.log("startDate : " + startDate);
		console.log("endDate : " + endDate);
		refleshPerformance();
	}

	useEffect(() => {
		(async function () {
			await refleshPerformance();
			// setChartDataList([
			// 	{ date: new Date(2024,2,1), website_click:104, booking:80, total:184 },
			// 	{ date: new Date(2024,3,1), website_click:290, booking:40, total:330 },
			// 	{ date: new Date(2024,4,1), website_click:240, booking:50, total:290 },
			// 	{ date: new Date(2024,5,1), website_click:125, booking:20, total:145 },
			// 	{ date: new Date(2024,6,1), website_click:190, booking:30, total:220 },
			// 	{ date: new Date(2024,7,1), website_click:20, booking:14, total:34 },
			// ]);
		})();
	}, []);

	return (
		<div>
			<Box mb={2}>
				<Typography>期間</Typography>
				<Box pt={2}>
					<TextField name="start_date" disabled={loading} label="開始" key={"st"+startDate} defaultValue={startDate} variant="outlined" type="month" InputLabelProps={{shrink: true}} size="small" sx={{marginRight:2}} onChange={handle_change_date} />
					<TextField name="end_date" disabled={loading} label="終了" key={"ed"+endDate} defaultValue={endDate} variant="outlined" type="month" InputLabelProps={{shrink: true}} size="small" sx={{marginRight:2}} onChange={handle_change_date} />
					<Button variant="contained" onClick={handle_date_submit} disabled={loading}>検索</Button>
				</Box>
			</Box>
			<Box pb={0} mb={-2}>
				<Typography sx={{ fontSize: "3.5rem" }}>{interactionTotal.toLocaleString()||"-"}</Typography>
				<Typography>ビジネスプロフィールで実施されたインタラクション</Typography>
			</Box>
			<Box>
				<LineChart
					xAxis={[{
						// data: [1, 2, 3, 5, 8, 10],
						// dataKey : "label",
						// label:"aa",
						dataKey : "date",
						scaleType: "time",
						valueFormatter: (date) => (date.getMonth()+1)+"/"+date.getDate(),
						// valueFormatter: (date) => date.toLocaleDateString("ja-JP", {year: "numeric",month: "2-digit",day: "2-digit"}).replaceAll('/', '-'),
						// valueFormatter: (value) => ( value%1 === 0 ) ? value.toString()+"月" : "",
						// valueFormatter: (date) => date.getDate().toString(),
						// valueFormatter: (date) => (date.getDate() === 1) ? date.getFullYear() +"/"+(date.getMonth()+1)+"/"+date.getDate() : "",
					}]}
					// series={[
					// 	{ dataKey : "website_click", showMark: false },
					// 	{ dataKey : "booking", showMark: false },
					// 	{ dataKey : "total", showMark: false },
					// ]}
					series={Object.keys(keyToLabel).map((key) => ({
						dataKey: key,
						label: keyToLabel[key],
						showMark: false,
						curve: "linear", 
					}))}
					dataset={chartDataList}
					width={1000}
					height={400}
					grid={{ vertical: true, horizontal: true }}
					// margin={{ left: 30, right: 30, top: 30, bottom: 30 }}
					slotProps={{legend:{hidden:true}}}
				/>
			</Box>
			<Box mt={2}>
				<Typography sx={{ fontSize: ".9rem", fontWeight: "bold" }}>ユーザーがビジネスを見つけた経路</Typography>
				<Grid container spacing={2} mt={0} mb={2}>
					<Grid item xs={12} md={6} display="table">
						<Card>
							<CardContent>
								<Box sx={{ py: 0, mb:3 }}>
									<Typography sx={{ fontSize: "3rem" }}>
										{(impressionTotalDict.BUSINESS_IMPRESSIONS_DESKTOP_MAPS+impressionTotalDict.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH+
										impressionTotalDict.BUSINESS_IMPRESSIONS_MOBILE_MAPS+impressionTotalDict.BUSINESS_IMPRESSIONS_MOBILE_SEARCH).toLocaleString()}</Typography>
									<Typography>ビジネスプロフィールを閲覧したユーザー数</Typography>
								</Box>
								<Box mb={2}>
									<Typography>プラットフォームとデバイスの内訳</Typography>
									<Typography sx={{ fontSize: ".8rem" }}>プロフィールの検索に使用されたプラットフォームとデバイス</Typography>
								</Box>
								<PieChart
									series={[
										{
										data: [
											{ id: 3, value: impressionTotalDict.BUSINESS_IMPRESSIONS_MOBILE_SEARCH, label:"Google 検索 - モバイル" },
											{ id: 2, value: impressionTotalDict.BUSINESS_IMPRESSIONS_MOBILE_MAPS, label:"Google マップ - モバイル" },
											{ id: 1, value: impressionTotalDict.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH, label:"Google 検索 - パソコン" },
											{ id: 0, value: impressionTotalDict.BUSINESS_IMPRESSIONS_DESKTOP_MAPS, label:"Google マップ - パソコン" },
										],
										},
									]}
									width={480}
									height={200}
									margin={{left:-140}}
								/>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} md={6} display="table">
						<Card>
							<CardContent>
								<Box sx={{ py: 0, mb:0 }}>
									<Typography sx={{ fontSize: "3rem" }}>{searchKeywordTotal.toLocaleString()||"-"}</Typography>
									<Typography>ビジネス プロフィールの表示につながった検索数</Typography>
								</Box>

								<Box>
									<LineChart
										xAxis={[{
											// data: [1, 2, 3, 5, 8, 10],
											// dataKey : "label",
											// label:"aa",
											dataKey : "date",
											scaleType: "time",
											valueFormatter: (date) => (date.getMonth()+1)+"/"+date.getDate(),
											// valueFormatter: (date) => date.toLocaleDateString("ja-JP", {year: "numeric",month: "2-digit",day: "2-digit"}).replaceAll('/', '-'),
											// valueFormatter: (value) => ( value%1 === 0 ) ? value.toString()+"月" : "",
											// valueFormatter: (date) => date.getDate().toString(),
											// valueFormatter: (date) => (date.getDate() === 1) ? date.getFullYear() +"/"+(date.getMonth()+1)+"/"+date.getDate() : "",
										}]}
										series={[
											{ dataKey : "search_count", showMark: false, label:"表示につながった検索数", curve:"linear" },
										]}
										dataset={searchChartDataList}
										width={500}
										height={300}
										grid={{ vertical: true, horizontal: true }}
										// margin={{ left: 30, right: 30, top: 30, bottom: 30 }}
										slotProps={{legend:{hidden:true}}}
									/>
								</Box>

								<Box mb={2}>
									<Typography>検索内容</Typography>
									<Typography sx={{ fontSize: ".8rem" }}>ビジネスプロフィールの表示につながった検索語句</Typography>
								</Box>
								<Box height={"200px"} sx={{overflowY:"scroll"}}>
									{searchKeywordList.map((item, index)=>(
										<Box sx={{display:"flex"}} mb={1}>
											<Typography width={"2.5rem"} sx={{flexShrink:0}}>{index+1}.</Typography>
											<Typography pr={3}>{item.search_keyword}</Typography>
											{item.value ? (
												<Typography pr={2} ml={"auto"}>{item.value.toLocaleString()}</Typography>
											) : (
												<Typography pr={2} ml={"auto"}>&lt; {item.threshold}</Typography>
											)}
										</Box>
									))}
								</Box>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Box>
		</div>
	);
}
