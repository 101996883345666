import { useNickFetch } from '#app/hooks/useNickFetch';
import { Shop } from '#app/models';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import ShopDrawer from './ShopDrawer';

type GetShopResponse = {
	shop: Shop,
}
export default function ShopLayout() {
	const params = useParams();
	const shop_id = Number(params.shop_id);
	const [shop, setShop] = useState<Shop>(new Shop());
	const { nickFetch } = useNickFetch();

	/**
	 * 
	 */
	const reloadShop = async () => {
		const res = await nickFetch("/get_shop/", {
			shop_id: shop_id,
		});
		if (!res.error) {
			// ok.
			const data = res.data as GetShopResponse;
			if (data.shop) setShop( new Shop(data.shop) );
		}
	}
	useEffect(() => {
		reloadShop();
	}, []);

	return (
		<div style={{ display: "flex" }}>
			<ShopDrawer shop={shop} />
			<Box sx={{ flexGrow: 1, pt: 1, overflow: "hidden" }}>
				{shop.id ?
					// ここに子ページの内容が入る.
					(<Outlet context={{ shop: shop, reloadShop: reloadShop }} />)
					: null
				}
			</Box>
		</div>
	);
}

/**
 * 子ページ（Outlet）に渡すコンテキスト.
 * props的な使い方だしpropsと呼ぶぞ. キモく感じてきたら直すぞ.
 * https://reactrouter.com/docs/en/v6/hooks/use-outlet-context
 */
type ShopPageContextType = {
	shop: Shop;
	reloadShop: () => Promise<void>;
};
export function useShopPageProps() {
	return useOutletContext<ShopPageContextType>();
}
