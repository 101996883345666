import NickDialog from "#app/components/NickDialog";
import S3Uploader from "#app/components/S3Uploader";
import { useNickFetch } from "#app/hooks/useNickFetch";
import { useNotice } from "#app/hooks/useNotice";
import { Post } from "#app/models";
import { CallToActionType, getCallToActionTypeLabel, getLocalPostTopicTypeLabel, LocalPost, LocalPostTopicType } from "#app/models/LocalPost";
import { nl2br } from "#app/utils";
import dayjs from "#app/utils/custom_dayjs";
import { Avatar, Box, Button, Card, CardActions, CardContent, CardMedia, Chip, Dialog, DialogActions, DialogContent, DialogContentText, Divider, FormControl, Grid, IconButton, ImageList, ImageListItem, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';

const Hoge = {
	SMALL: "SMALL",
	MEDIUM: "MEDIUM",
	hello: () => { },
} as const;
// type Hoge = (typeof Hoge)[number];
// type Hoge = keyof typeof Hoge;
type Hoge = Exclude<keyof typeof Hoge, "hello">;

/**
 * 投稿を作成したり編集したり.
 * 
 */

type Props = {
	open: boolean,
	shop_id: number,
	post_id?: number,
	onClose?: (() => void),//?を付けると任意になるんだって！
	onComplete?: (() => void),
}
export default function PostEditDialog(props: Props) {
	// const post = props.post;
	// const post = new LocalPost({});
	// const [localPost, setLocalPost] = useState<LocalPost>(new LocalPost({ topicType: "STANDARD" }));
	const [post, setPost] = useState<Post>(new Post());
	const { nickFetch } = useNickFetch();
	const { addNotice } = useNotice();
	const [validateErrors, setValidateErrors] = useState<{ [key: string]: string }>({});

	// 閉じる処理. 渡された関数実行するだけ.
	const handleEditClose = () => {
		if (props.onClose) props.onClose();
	};
	/**
	 * Post取得.
	 */
	const handleOpen = function () {
		if (!props.post_id) {
			return setPost(new Post());
		}
		(async () => {
			const res = await nickFetch("/get_post/", {
				post_id: props.post_id,
			});
			if (!res.error && res.data.post) {
				// ok.
				setPost(new Post(res.data.post));
			}
		})();
	}

	/**
	 * 保存.
	 */
	const savePost = async function (isDraft: boolean = true) {
		/**
		 * 適当にバリデート.
		 */
		const localPost = post.get_local_post();
		const valierr: (typeof validateErrors) = {};
		if (!localPost.summary) {
			valierr["summary"] = "必須項目です";
		}
		if (localPost.canSetEvent()) {
			if (!localPost.event?.title) valierr["event_title"] = "必須項目です";
			if (!localPost.event?.schedule.startDate) valierr["event_startDate"] = "必須項目です";
			if (!localPost.event?.schedule.endDate) valierr["event_endDate"] = "必須項目です";
		}
		if (0 < Object.keys(valierr).length) {
			return setValidateErrors(valierr);
		}
		/**
		 * 整形.
		 */
		if (!localPost.canSetButton()) { localPost.callToAction = null; }
		if (!localPost.canSetMedia()) { localPost.media = null; }
		if (!localPost.canSetEvent()) { localPost.event = null; }
		if (!localPost.canSetOffer()) { localPost.offer = null; }

		/**
		 * API叩く.
		 */
		const body = {
			local_post_json: JSON.stringify(localPost),
			post_dt: post.post_dt,
			shop_id: props.shop_id,
			post_id: props.post_id,
			is_draft: isDraft,
		}
		const res = await nickFetch("/save_post/", body);
		if (!res.error) {
			// ok.
			if (props.onComplete) props.onComplete(); // 親側でユーザー取り直し.
			setValidateErrors({});
			handleEditClose();
			addNotice("保存しました", "success");
		}
	}
	/**
	 * 削除ボタン押された.
	 */
	const handleDelete = async function () {
		if (!window.confirm("本当に削除しますか？")) return;
		const res = await nickFetch("/delete_post/", {
			post_id: props.post_id,
		});
		if (!res.error) {
			// ok.
			if (props.onComplete) props.onComplete(); // 親側でユーザー取り直し.
			handleEditClose();
			addNotice("削除しました", "success");
		}
	}
	/**
	 * 下書きボタン押された.
	 */
	const handleDraftSave = async function () {
		savePost(true);
	}
	/**
	 * 投稿ボタン押された.
	 */
	const handleSave = async function () {
		savePost(false);
	}

	/**
	 * 各フィールドの変更を反映.
	 * 冗長過ぎる気がする.
	 */
	const handleChangeTopicType = (e: SelectChangeEvent<LocalPostTopicType>) => {
		if (Object.values(LocalPostTopicType).includes(e.target.value as LocalPostTopicType)) {
			const localPost = post.get_local_post();
			localPost.topicType = e.target.value as LocalPostTopicType;
			post.set_local_post(localPost);
			setPost(Object.assign(new Post(), post));//こうしないとrenderされないぽよ.
		}
	}
	const handleChangeCallToActionType = (e: SelectChangeEvent<CallToActionType>) => {
		const selected_actionType = e.target.value as CallToActionType;
		if (Object.values(CallToActionType).includes(selected_actionType)) {
			// post.topicType = e.target.value as CallToActionType;
			const localPost = post.get_local_post();
			if (selected_actionType == CallToActionType.ACTION_TYPE_UNSPECIFIED) {
				localPost.callToAction = null;
			} else {
				localPost.callToAction = {
					actionType: e.target.value as CallToActionType,
					url: localPost.callToAction?.url || "",
				}
			}
			post.set_local_post(localPost);
			setPost(Object.assign(new Post(), post));//こうしないとrenderされないぽよ.
		}
	}
	const handleChangeEventTitle = (e: any) => {
		const localPost = post.get_local_post();
		localPost.setEventTitle(e.target.value);
		post.set_local_post(localPost);
		setPost(Object.assign(new Post(), post));
	}
	const handleChangeEventStartDate = (e: any) => {
		const localPost = post.get_local_post();
		localPost.setEventStartDate(e.target.value);
		post.set_local_post(localPost);
		setPost(Object.assign(new Post(), post));
	}
	const handleChangeEventStartTime = (e: any) => {
		const localPost = post.get_local_post();
		console.log(e.target.value);
		localPost.setEventStartTime(e.target.value);
		post.set_local_post(localPost);
		setPost(Object.assign(new Post(), post));
	}
	const handleChangeEventEndDate = (e: any) => {
		const localPost = post.get_local_post();
		localPost.setEventEndDate(e.target.value);
		post.set_local_post(localPost);
		setPost(Object.assign(new Post(), post));
	}
	const handleChangeEventEndTime = (e: any) => {
		const localPost = post.get_local_post();
		localPost.setEventEndTime(e.target.value);
		post.set_local_post(localPost);
		setPost(Object.assign(new Post(), post));
	}
	const handleChangeSummary = (e: any) => {
		const localPost = post.get_local_post();
		localPost.summary = e.target.value;
		post.set_local_post(localPost);
		setPost(Object.assign(new Post(), post));
	}
	const handleChangeOfferCouponCode = (e: any) => {
		const localPost = post.get_local_post();
		localPost.setOfferCouponCode(e.target.value);
		post.set_local_post(localPost);
		setPost(Object.assign(new Post(), post));
	}
	const handleChangeOfferRedeemOnlineUrl = (e: any) => {
		const localPost = post.get_local_post();
		localPost.setOfferRedeemOnlineUrl(e.target.value);
		post.set_local_post(localPost);
		setPost(Object.assign(new Post(), post));
	}
	const handleChangeOfferTermsConditions = (e: any) => {
		const localPost = post.get_local_post();
		localPost.setOfferTermsConditions(e.target.value);
		post.set_local_post(localPost);
		setPost(Object.assign(new Post(), post));
	}
	const handleChangePostDate = (e: any) => {
		const date = dayjs(post.post_dt);
		post.post_dt = e.target.value + " " + (date.isValid() ? date.format("HH:mm") : "");
		setPost(Object.assign(new Post(), post));
	}
	const handleChangePostTime = (e: any) => {
		let date = dayjs(post.post_dt);
		if (!date.isValid()) date = dayjs();
		post.post_dt = date.format("YYYY-MM-DD") + " " + e.target.value;
		setPost(Object.assign(new Post(), post));
	}
	const handleChangeActionUrl = (e: any) => {
		const localPost = post.get_local_post();
		if (localPost.callToAction) {
			localPost.callToAction.url = e.target.value;
		}
		post.set_local_post(localPost);
		setPost(Object.assign(new Post(), post));
	}

	/**
	 * 画像まわり.
	 */
	const handleS3Upload = (origin_url: string) => {
		const localPost = post.get_local_post();
		localPost.addMedia(origin_url);
		post.set_local_post(localPost);
		setPost(Object.assign(new Post(), post));

	}
	const handleDeleteMedia = (index: number) => {
		if (!window.confirm("本当に削除しますか？")) return;
		const localPost = post.get_local_post();
		localPost.deleteMedia(index);
		post.set_local_post(localPost);
		setPost(Object.assign(new Post(), post));
	}

	// 
	const localPost = post.get_local_post();

	return (
		<>
			<NickDialog
				open={props.open}
				onClose={handleEditClose}
				onOpen={handleOpen}
				fullWidth={true}
				maxWidth="sm"
			>
				<Box component="form" noValidate>
					<Typography variant="h6" sx={{ textAlign: "center", pt: 3 }}>投稿{post.id ? "編集" : "作成"}</Typography>
					<DialogContent>
						<DialogContentText variant="body2" sx={{ textAlign: "center", mb: 2 }}>

						</DialogContentText>
						<Box>
							{/* * * * * * * *
							* 投稿タイプ.
							* * * * * * * * */}
							<Box pb={2}>
								<FormControl fullWidth>
									<InputLabel>投稿タイプ</InputLabel>
									<Select
										label="投稿タイプ"
										disabled={post.id ? true : false}
										onChange={handleChangeTopicType}
										value={localPost.topicType}
									>
										{Object.values(LocalPostTopicType)
											.filter(topicType => topicType != LocalPostTopicType.LOCAL_POST_TOPIC_TYPE_UNSPECIFIED)
											.map((topicType, key) => (<MenuItem key={key} value={topicType}>{getLocalPostTopicTypeLabel(topicType)}</MenuItem>))}
									</Select>
								</FormControl>
							</Box>

							{/* * * * * * * *
							* 画像登録.
							* * * * * * * * */}
							{localPost.canSetMedia() ? (
								<Box py={2}>
									<Typography mb={1}>画像登録</Typography>
									<Box>
										{localPost.media?.map((_, index) => (
											<Box key={index} display="inline-block" mr={1} mb={1} position="relative">
												<Avatar variant="rounded" src={localPost.getMediaUrl(index)} sx={{ width: 160, height: 120 }} />
												<IconButton
													sx={{ position: "absolute", bottom: 0, right: 0 }}
													onClick={()=>{handleDeleteMedia(index)}}>
													<DeleteIcon />
												</IconButton>
											</Box>
										))}
									</Box>
									<S3Uploader
										shop_id={props.shop_id}
										onComplete={handleS3Upload}
										btn_text="画像を追加"
									/>
								</Box>
							) : null}

							{/* * * * * * * *
							* イベント.
							* * * * * * * * */}
							{localPost.canSetEvent() ? (
								<Box py={2}>
									<Box>
										<TextField
											fullWidth
											required
											multiline
											// placeholder="プレースホルダー"
											margin="normal"//or normal
											type="text"
											autoComplete="off"
											label="タイトル"
											value={localPost.event?.title}
											onChange={handleChangeEventTitle}
											error={"event_title" in validateErrors}
											helperText={validateErrors.event_title ? validateErrors.event_title : null}
										/>
									</Box>
									<Box display={"flex"}>
										<TextField
											fullWidth
											required
											InputLabelProps={{ shrink: true }}//にゅっとした状態を強制.
											margin="normal"//or normal
											type="date"
											autoComplete="off"
											label="開始日"
											sx={{ mr: 1 }}
											onChange={handleChangeEventStartDate}
											value={localPost.getEventStartDate()}
											error={"event_startDate" in validateErrors}
											helperText={validateErrors.event_startDate ? validateErrors.event_startDate : null}
										/>
										<TextField
											fullWidth
											InputLabelProps={{ shrink: true }}//にゅっとした状態を強制.
											margin="normal"//or normal
											type="time"
											autoComplete="off"
											label="開始時間"
											onChange={handleChangeEventStartTime}
											value={localPost.getEventStartTime()}
										/>
									</Box>
									<Box display={"flex"}>
										<TextField
											fullWidth
											required
											InputLabelProps={{ shrink: true }}//にゅっとした状態を強制.
											margin="normal"//or normal
											type="date"
											autoComplete="off"
											label="終了日"
											sx={{ mr: 1 }}
											onChange={handleChangeEventEndDate}
											value={localPost.getEventEndDate()}
											error={"event_endDate" in validateErrors}
											helperText={validateErrors.event_endDate ? validateErrors.event_endDate : null}
										/>
										<TextField
											fullWidth
											InputLabelProps={{ shrink: true }}//にゅっとした状態を強制.
											margin="normal"//or normal
											type="time"
											autoComplete="off"
											label="終了時間"
											onChange={handleChangeEventEndTime}
											value={localPost.getEventEndTime()}
										/>
									</Box>
								</Box>
							) : null}

							{/* * * * * * * *
							* サマリ.
							* * * * * * * * */}
							<Box py={2}>
								<TextField
									fullWidth
									required
									multiline
									// placeholder="プレースホルダー"
									margin="normal"//or normal
									type="text"
									autoComplete="off"
									label="詳細"
									name="summary"
									value={localPost.summary}
									onChange={handleChangeSummary}
									error={"summary" in validateErrors}
									helperText={validateErrors.summary ? validateErrors.summary : null}
								/>
							</Box>

							{/* * * * * * * *
							* オファー（特典, クーポン）.
							* * * * * * * * */}
							{localPost.canSetOffer() ? (
								<Box py={2}>
									<Typography mb={1}>特典の詳細（省略可）</Typography>
									<Box>
										<TextField
											fullWidth
											margin="normal"//or normal
											type="text"
											autoComplete="off"
											label="クーポンコード"
											value={localPost.offer?.couponCode}
											onChange={handleChangeOfferCouponCode}
										/>
									</Box>
									<Box>
										<TextField
											fullWidth
											margin="normal"//or normal
											type="text"
											autoComplete="off"
											label="特典利用へのリンク"
											value={localPost.offer?.redeemOnlineUrl}
											onChange={handleChangeOfferRedeemOnlineUrl}
										/>
									</Box>
									<Box>
										<TextField
											fullWidth
											multiline
											margin="normal"//or normal
											type="text"
											autoComplete="off"
											label="利用規約"
											value={localPost.offer?.termsConditions}
											onChange={handleChangeOfferTermsConditions}
										/>
									</Box>
								</Box>
							) : null}

							{/* * * * * * * *
							* ボタン.
							* * * * * * * * */}
							{localPost.canSetButton() ? (
								<Box py={2}>
									<Typography mt={3} mb={1}>ボタンの追加（省略可）</Typography>
									<FormControl sx={{ minWidth: "10rem" }} margin="normal">
										<InputLabel>ボタン</InputLabel>
										<Select
											label="ボタン"
											onChange={handleChangeCallToActionType}
											// defaultValue={post.callToAction?.actionType||CallToActionType.ACTION_TYPE_UNSPECIFIED}
											value={localPost.callToAction?.actionType || CallToActionType.ACTION_TYPE_UNSPECIFIED}
										>
											{Object.values(CallToActionType).map((actionType, key) => {
												if (actionType === CallToActionType.GET_OFFER) return;
												return (
													<MenuItem key={key} value={actionType}>{getCallToActionTypeLabel(actionType)}</MenuItem>
												)
											})}
										</Select>
									</FormControl>
									{localPost.callToAction && localPost.callToAction.actionType != CallToActionType.ACTION_TYPE_UNSPECIFIED ? (
										<TextField
											fullWidth
											margin="normal"//or normal
											type="text"
											autoComplete="off"
											label="ボタンのリンク"
											name="actionUrl"
											onChange={handleChangeActionUrl}
											value={localPost.callToAction.url}
										/>
									) : null}
								</Box>
							) : null}

							{/* * * * * * * *
							* 予約投稿日時.
							* * * * * * * * */}
							<Grid container spacing={2} mt={2} mb={2}>
								<Grid item xs={12} mb={-1}>
									<Typography>予約投稿日時</Typography>
								</Grid>
								<Grid item xs={6}>
									<TextField
										fullWidth
										InputLabelProps={{ shrink: true }}//にゅっとした状態を強制.
										margin="normal"//or normal
										type="date"
										autoComplete="off"
										label="日"
										name="post_date"
										onChange={handleChangePostDate}
										value={dayjs(post.post_dt).isValid() ? dayjs(post.post_dt).format("YYYY-MM-DD") : ""}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										fullWidth
										InputLabelProps={{ shrink: true }}//にゅっとした状態を強制.
										margin="normal"//or normal
										type="time"
										// inputProps={{step:60*10}}//うーん効かない..
										autoComplete="off"
										label="時間"
										name="post_time"
										onChange={handleChangePostTime}
										value={dayjs(post.post_dt).isValid() ? dayjs(post.post_dt).format("HH:mm") : ""}
									/>
								</Grid>
							</Grid>

						</Box>
					</DialogContent>
					<Divider />
					<DialogActions sx={{ p: 2 }}>
						<Button color="inherit" onClick={handleEditClose} sx={{ marginRight: "auto" }}>キャンセル</Button>
						{post.id ? <Button variant="text" color="error" onClick={handleDelete}>削除</Button> : null}
						<Button variant="outlined" onClick={handleDraftSave}>下書き保存</Button>
						<Button variant="contained" onClick={handleSave}>投稿</Button>
					</DialogActions>
				</Box>
			</NickDialog>
		</>
	);
}
