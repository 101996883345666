import { ShopSync, SyncStatus } from "#app/models";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";

/**
 * MUIのダイアログに onOpen が無かったのでできるようにした.
 */
type SyncStatusBarProps = {
	sync: ShopSync,
}

export default function SyncStatusBar({ sync, ...props }: SyncStatusBarProps) {
	const [saving, setSaving] = useState<boolean>(false);

	useEffect(() => {
		// // console.log("props.open = " + props.open);
		// if ( prev_open && !props.open ) {
		// 	prev_open = false;
		// 	// console.log("閉じた！");
		// }
		// if (!prev_open && props.open) {
		// 	// 開いた！
		// 	prev_open = true;
		// 	if (onOpen) onOpen();
		// 	// console.log("開いた!");
		// }
		// ここから関数を返すと、Reactはアンマウントの直前にそれを呼び出す.
		return () => {
			// console.log("unmount! ");
		}
	});
	/**
	 * 保存ボタン押された.
	 */
	const handleSubmit = async function () {
		/**
		 * 適当にバリデート.
		 */
		setSaving(true);
		/**
		 * API叩く.
		 */
		// const body = {
		// 	hpb_salon_id: shop.hpb_salon_id,
		// 	shop_id: shop.id,
		// }
		// const res = await nickFetch("/save_shop_hpb_salon_id/", body);
		// if (!res.error) {
		// 	// ok.
		// 	setValidateErrors({});
		// 	addNotice("保存しました", "success");
		// }
		setSaving(false);
	}
	return (
		<Box>
			<Typography>{sync.getStatusLabel()}: {sync.update_dt}</Typography>
			{sync.status == SyncStatus.ERROR ? (
				<Typography sx={{ fontSize: ".8rem",color:"red" }}>{sync.log_line}</Typography>
			) : null}
			{/* <LoadingButton
				variant="outlined"
				size="small"
				onClick={() => { handleSubmit() }}
				loading={saving}
			>今すぐ同期</LoadingButton> */}
		</Box>
	);
}
