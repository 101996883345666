import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { SnackbarOrigin, useSnackbar, VariantType } from 'notistack';

export function useNotice() {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	/**
	 * にゅっと出る通知.
	 * @param text 表示したいテキスト.
	 * @param variant VariantType
	 */
	const addNotice = function (text: string, variant: VariantType = "default", position: "top" | "bottom" = "top") {
		const anchorOrigin:SnackbarOrigin = position === "top" ? { vertical: 'top', horizontal: 'right' } : { vertical: 'bottom', horizontal: "center" };
		const snackId = enqueueSnackbar(text, {
			variant: variant,
			anchorOrigin: anchorOrigin,
			autoHideDuration: 5000,
			// persist: true, //勝手に閉じないようにできる.
			// sx: { //スタイルも設定できる. -> tsにしたらできなくなった.
			// 	"& .SnackbarItem-message": {
			// 		padding: 0,
			// 		// fontWeight: "bold",
			// 	}
			// },
			action: (<IconButton onClick={() => closeSnackbar(snackId)} size="small" color="inherit"><CloseIcon fontSize="small" /></IconButton>),
		});
	}
	return { addNotice };
}