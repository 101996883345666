import { useDrawerOpen } from '#app/hooks/useDrawerOpen';
import { useViewer } from '#app/hooks/useViewer';
import { Shop } from '#app/models';
import MailIcon from '@mui/icons-material/Mail';
import PhotoIcon from '@mui/icons-material/Photo';
import EditNoteIcon from '@mui/icons-material/EditNote';
import SettingsIcon from '@mui/icons-material/Settings';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import SsidChartIcon from '@mui/icons-material/SsidChart';
// material-ui
import { Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import MyListItem from './MyListItem';


type Props = {
	window: any;
	shop: Shop;
}
const ShopDrawer = (props: Props) => {
	const { window, shop } = props;
	const container = window !== undefined ? () => window().document.body : undefined;
	const { viewer, setViewer } = useViewer();
	// ドロワー開閉設定.
	const { drawerOpen, toggleDrawerOpen } = useDrawerOpen();
	// サイズ設定.
	const drawerWidth = 240;
	const headerHeight = viewer.isMaster() ? 100 : 65;//dense 49

	const drawer = (
		<div>
			<Box px={2} py={2}>
				<Typography>{shop.title}</Typography>
				<Typography variant='caption'>{shop.getTypeLabel()}</Typography>
			</Box>
			<List>
				{/* <ListSubheader>あああ</ListSubheader> */}
				<MyListItem text="ダッシュボード" icon={<NewspaperIcon />} to={"/shops/" + shop.id} onClick={toggleDrawerOpen} />
				<ListSubheader>投稿</ListSubheader>
				<MyListItem text="投稿" icon={<EditNoteIcon />} to={"/shops/" + shop.id + "/posts"} onClick={toggleDrawerOpen} />
				{/* <MyListItem text="投稿済み" icon={<MailIcon />} to={"/shops/" + shop.id + "/posts/google"} /> */}
				<ListSubheader>写真</ListSubheader>
				<MyListItem text="写真" icon={<PhotoIcon />} to={"/shops/" + shop.id + "/photos"} onClick={toggleDrawerOpen} />
				{/* <ListSubheader>分析</ListSubheader>
				<MyListItem text="分析" icon={<MailIcon />} to={"/shops/" + shop.id + "/insights"} /> */}
				<ListSubheader>設定</ListSubheader>
				<MyListItem text="ホットペッパー連携" icon={<SettingsIcon />} to={"/shops/" + shop.id + "/settings/sync/hotpepper"} onClick={toggleDrawerOpen} />
				<MyListItem text="インスタグラム連携" icon={<SettingsIcon />}  to={"/shops/" + shop.id + "/settings/sync/instagram"} onClick={toggleDrawerOpen} />
				{viewer.isMaster() || parseInt(viewer.id+"") === 87 ? (
					<>
						{viewer.isMaster() ? (
							<ListSubheader>分析（管理者のみ）</ListSubheader>
						) : <ListSubheader>分析</ListSubheader>}
						<MyListItem text="パフォーマンス" icon={<SsidChartIcon />} to={"/shops/" + shop.id + "/performance"} onClick={toggleDrawerOpen} />
					</>
				) : null}
			</List>
			{/* <Divider /> */}
		</div>
	);
	return (
		<Box
			component="nav"
			sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
			aria-label="mailbox folders"
		>
			{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
			{/* 小さい画面用 */}
			<Drawer
				container={container}
				variant="temporary"
				open={drawerOpen}
				onClose={toggleDrawerOpen}
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
				}}
				sx={{
					display: { xs: 'block', sm: 'none' },
					'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
				}}
			>
				{drawer}
			</Drawer>
			{/* 大きい画面用 */}
			<Drawer
				variant="permanent"
				sx={{
					display: { xs: 'none', sm: 'block' },
					'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, top: headerHeight, borderRight: "none" },
				}}
				open
			>
				{drawer}
			</Drawer>
		</Box>
	);
};

ShopDrawer.propTypes = {
	window: PropTypes.object,
};

export default ShopDrawer;
