import NickDialog from "#app/components/NickDialog";
import S3Uploader from "#app/components/S3Uploader";
import { useNickFetch } from "#app/hooks/useNickFetch";
import { useNotice } from "#app/hooks/useNotice";
import { Photo } from "#app/models";
import dayjs from "#app/utils/custom_dayjs";
import { Avatar, Box, Button, DialogActions, DialogContent, DialogContentText, Divider, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";

/**
 * 投稿を作成したり編集したり.
 * 
 */

type Props = {
	open: boolean,
	shop_id: number,
	photo_id?: number,
	onClose?: (() => void),//?を付けると任意になるんだって！
	onComplete?: (() => void),
}
export default function PhotoEditDialog(props: Props) {
	// const post = props.post;
	// const post = new LocalPhoto({});
	// const [localPhoto, setLocalPhoto] = useState<LocalPhoto>(new LocalPhoto({ topicType: "STANDARD" }));
	const [photo, setPhoto] = useState<Photo>(new Photo());
	const { nickFetch } = useNickFetch();
	const { addNotice } = useNotice();
	const [validateErrors, setValidateErrors] = useState<{ [key: string]: string }>({});

	// 閉じる処理. 渡された関数実行するだけ.
	const handleEditClose = () => {
		if (props.onClose) props.onClose();
	};
	/**
	 * Photo取得.
	 */
	const handleOpen = function () {
		setValidateErrors({});
		setPhoto(new Photo());
		if (!props.photo_id) {
			return setPhoto(new Photo());
		}
		(async () => {
			const res = await nickFetch("/get_photo/", {
				photo_id: props.photo_id,
			});
			if (!res.error && res.data.photo) {
				// ok.
				setPhoto(new Photo(res.data.photo));
			}
		})();
	}

	/**
	 * 保存.
	 */
	const savePhoto = async function (isDraft: boolean = true) {
		/**
		 * 適当にバリデート.
		 */
		const mediaItem = photo.get_media_item();
		const valierr: (typeof validateErrors) = {};
		if (!mediaItem.sourceUrl) {
			valierr["sourceUrl"] = "必須項目です";
		}
		if (0 < Object.keys(valierr).length) {
			return setValidateErrors(valierr);
		}

		/**
		 * API叩く.
		 */
		const body = {
			media_item_json: JSON.stringify(mediaItem),
			post_dt: photo.post_dt,
			shop_id: props.shop_id,
			photo_id: props.photo_id,
			is_draft: isDraft,
		}
		const res = await nickFetch("/save_photo/", body);
		if (!res.error) {
			// ok.
			if (props.onComplete) props.onComplete(); // 親側でユーザー取り直し.
			setValidateErrors({});
			handleEditClose();
			addNotice("保存しました", "success");
		}
	}
	/**
	 * 削除ボタン押された.
	 */
	const handleDelete = async function () {
		if (!window.confirm("本当に削除しますか？")) return;
		const res = await nickFetch("/delete_photo/", {
			photo_id: props.photo_id,
		});
		if (!res.error) {
			// ok.
			if (props.onComplete) props.onComplete(); // 親側でユーザー取り直し.
			handleEditClose();
			addNotice("削除しました", "success");
		}
	}
	/**
	 * 下書きボタン押された.
	 */
	const handleDraftSave = async function () {
		savePhoto(true);
	}
	/**
	 * 投稿ボタン押された.
	 */
	const handleSave = async function () {
		savePhoto(false);
	}

	/**
	 * 各フィールドの変更を反映.
	 */
	const handleChangePostDate = (e: any) => {
		const date = dayjs(photo.post_dt);
		photo.post_dt = e.target.value + " " + (date.isValid() ? date.format("HH:mm") : "");
		setPhoto(Object.assign(new Photo(), photo));
	}
	const handleChangePostTime = (e: any) => {
		let date = dayjs(photo.post_dt);
		if (!date.isValid()) date = dayjs();
		photo.post_dt = date.format("YYYY-MM-DD") + " " + e.target.value;
		setPhoto(Object.assign(new Photo(), photo));
	}

	/**
	 * 画像まわり.
	 */
	const handleS3Upload = (origin_url: string) => {
		const mediaItem = photo.get_media_item();
		mediaItem.sourceUrl = origin_url;
		photo.set_media_item(mediaItem);
		setPhoto(Object.assign(new Photo(), photo));

	}

	// 
	const mediaItem = photo.get_media_item();

	return (
		<>
			<NickDialog
				open={props.open}
				onClose={handleEditClose}
				onOpen={handleOpen}
				fullWidth={true}
				maxWidth="sm"
			>
				<Box component="form" noValidate>
					<Typography variant="h6" sx={{ textAlign: "center", pt: 3 }}>写真{photo.id ? "編集" : "作成"}</Typography>
					<DialogContent>
						<Box>
							{/* * * * * * * *
							* 画像登録.
							* * * * * * * * */}
							<Box pb={2}>
								<Typography mb={1}>画像</Typography>
								{mediaItem.sourceUrl ? (
									<Box>
										<Box display="inline-block" mr={1} mb={1} position="relative">
											<Avatar variant="rounded" src={mediaItem.sourceUrl} sx={{ width: 270, height: 360 }} />
										</Box>
									</Box>
								) : null}
								{!photo.media_item_name ? (
									<S3Uploader
										shop_id={props.shop_id}
										onComplete={handleS3Upload}
										btn_text="画像登録"
									/>
								) : null}
								{"sourceUrl" in validateErrors ? (
									<Typography color="red" sx={{ mt: 1 }}>必須項目です</Typography>
								) : null}
							</Box>

							{/* * * * * * * *
							* 予約投稿日時.
							* * * * * * * * */}
							<Grid container spacing={2} mb={2}>
								<Grid item xs={12} mb={-1}>
									<Typography>予約投稿日時</Typography>
								</Grid>
								<Grid item xs={6}>
									<TextField
										fullWidth
										InputLabelProps={{ shrink: true }}//にゅっとした状態を強制.
										margin="normal"//or normal
										type="date"
										autoComplete="off"
										label="日"
										name="post_date"
										onChange={handleChangePostDate}
										value={dayjs(photo.post_dt).isValid() ? dayjs(photo.post_dt).format("YYYY-MM-DD") : ""}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										fullWidth
										InputLabelProps={{ shrink: true }}//にゅっとした状態を強制.
										margin="normal"//or normal
										type="time"
										// inputProps={{step:60*10}}//うーん効かない..
										autoComplete="off"
										label="時間"
										name="post_time"
										onChange={handleChangePostTime}
										value={dayjs(photo.post_dt).isValid() ? dayjs(photo.post_dt).format("HH:mm") : ""}
									/>
								</Grid>
							</Grid>

						</Box>
					</DialogContent>
					<Divider />
					<DialogActions sx={{ p: 2 }}>
						<Button color="inherit" onClick={handleEditClose} sx={{ marginRight: "auto" }}>キャンセル</Button>
						{photo.id ? <Button variant="text" color="error" onClick={handleDelete}>削除</Button> : null}
						<Button variant="outlined" onClick={handleDraftSave}>下書き保存</Button>
						<Button variant="contained" onClick={handleSave}>投稿</Button>
					</DialogActions>
				</Box>
			</NickDialog>
		</>
	);
}
