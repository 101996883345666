import { Box, Button, Typography, DialogContent, DialogActions, List, ListItem, ListItemAvatar, Avatar, ListItemText, DialogProps, CircularProgress, ListSubheader } from "@mui/material";
import { useNickFetch } from "#app/hooks/useNickFetch";
import { useState } from "react";
import { useNotice } from "#app/hooks/useNotice";
import {User } from "#app/models";
import NickDialog from "#app/components/NickDialog";
import LoadingButton from "@mui/lab/LoadingButton";
import DownloadIcon from '@mui/icons-material/Download';

/**
 * components 側に置いたほうがいいのかもしれない.
 * 別の場所で使う予定もないし users/Detail/ に置いてる.
 */

type Props = {
	open: boolean,
	user: User,
	onComplete?: (() => void),
}
type GoogleLocation = {
	id: string;
	name:string;
	title:string;
	thumbnailUrl:string;
	profile:string;
	storefrontAddress:string;
	serviceArea:string;
	storeCode:string;
	is_imported:boolean;
}
type GoogleAccount = {
	id: string,
	name: string,
	google_locations: GoogleLocation[],
}
type GetsGoogleLocationResponse = {
	google_accounts:GoogleAccount[];
}
export default function LocationImportDialog(props: Props & DialogProps) {
	const { addNotice } = useNotice();
	const { nickFetch } = useNickFetch();
	const [google_accounts, setGoogleAccounts] = useState<GoogleAccount[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	// 閉じる処理. 渡された関数実行するだけ.
	const handleEditClose = () => {
		if (props.onClose) props.onClose({}, "backdropClick");
	};

	/**
	 * 開いた.
	 */
	const handleOpen = function () {
		(async () => {
			setLoading(true);
			setGoogleAccounts([]);
			const res = await nickFetch("/get_google_locations/", {
				user_id: props.user.id,
			});
			if (!res.error) {
				// ok.
				const data = res.data as GetsGoogleLocationResponse;
				setGoogleAccounts(data.google_accounts);
			}
			setLoading(false);
		})();
	}

	/**
	 * インポート.
	 */
	const [processingId, setProcessingId] = useState<string | null>(null);
	const handleImport = async function (google_location: GoogleLocation) {
		if (processingId) return;
		setProcessingId(google_location.id);
		/**
		 * API叩く.
		 */
		const res = await nickFetch("/import_location/", {
			user_id: props.user.id,
			location_id: google_location.id,
		});
		setProcessingId(null);
		if (res.error) {
			addNotice(res.message, "error", "bottom");
		} else {
			// 完了.
			if (props.onComplete) props.onComplete(); // 親側でShop取り直し.
			addNotice(google_location.title + " をインポートしました", "success");
			handleEditClose();
		}
	};
	return (
		<>
			<NickDialog
				open={props.open}
				onClose={props.onClose}
				fullWidth={true}
				maxWidth="sm"
				onOpen={handleOpen}
			>
				<Box>
					{/* <DialogTitle>編集</DialogTitle> */}
					<Typography variant="h6" sx={{ textAlign: "center", pt: 3 }}>ビジネスをインポート</Typography>
					{loading ? (
						<DialogContent
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								flexDirection: "column",
							}}
						>
							<CircularProgress />
							<Typography sx={{ mt: 1.5 }} variant="caption">読み込み中</Typography>
						</DialogContent>
					) : (
						<>
							<DialogContent>
								{/* <DialogContentText variant="body2" sx={{ textAlign: "center", mb: 2 }}>
										編集し保存ボタンを押して下さい
									</DialogContentText> */}
								<List sx={{ width: '100%', bgcolor: 'background.paper' }}>
									{google_accounts.map((google_account, i) => (
										<div key={i}>
											<ListSubheader sx={{ lineHeight: 2, mt: i === 0 ? 0 : 2 }}>{google_account.name}</ListSubheader>
											{google_account.google_locations.map((gl, j) => (
												<div key={j}>
													<ListItem
														alignItems="center"
														sx={{ px: 2 }}
													>
														<ListItemAvatar>
															<Avatar
																variant="square"
																sx={{ width: 50, height: 50, mr: 2 }}
																alt={gl.title}
																src={gl.thumbnailUrl}
															/>
														</ListItemAvatar>
														<ListItemText
															primaryTypographyProps={{ whiteSpace: "normal", overflow: "visible", textOverflow: "inherit" }}
															primary={gl.title}
															secondaryTypographyProps={{ whiteSpace: "normal", overflow: "visible", textOverflow: "inherit" }}
															secondary={
																<>
																	<Typography
																		component="span"
																		variant="body2"
																		color="text.primary"
																	>
																		{gl.storeCode ? gl.storeCode + " - " : ""}
																	</Typography>
																	{gl.storefrontAddress || gl.serviceArea}
																</>
															}
														/>
														<Box sx={{ whiteSpace: "nowrap", pl: 2 }}>
															<LoadingButton
																disabled={gl.is_imported}
																loading={processingId === gl.id}
																loadingPosition="start"
																startIcon={<DownloadIcon />}
																variant="outlined"
																size="small"
																onClick={() => { handleImport(gl) }}
															>
																{(processingId === gl.id) ? "インポート中" : "インポート"}
															</LoadingButton>
														</Box>
													</ListItem>
												</div>
											))}
										</div>
									))}
								</List>
							</DialogContent>
							<DialogActions sx={{ p: 2 }}>
								<Button color="inherit" onClick={handleEditClose} sx={{ marginRight: "6px" }}>閉じる</Button>
							</DialogActions>
						</>
					)}
				</Box>
			</NickDialog>
		</>
	);
}
