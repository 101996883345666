import { useNickFetch } from "#app/hooks/useNickFetch";
import { useNotice } from "#app/hooks/useNotice";
import { useViewer } from '#app/hooks/useViewer';
import { User } from "#app/models";
import { darkModeState, didInitState } from '#app/states';
import { Box, CircularProgress, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useEffect } from 'react';
import { useRecoilState } from "recoil";

const lightTheme = createTheme({
	palette: {
		mode: "light",
		primary: { main: "#1890ff" },
		// primary: { main: "#ff333f" },//メルカリ. ダークモード時は #da3e50
		// primary: { main: "#ffbc18" },
		// primary: { main: "#eab840" },
		
		background: {
			// default: "rgb(236, 239, 241)",
			default: "#ffffff",
		},
	},
	typography: {
		button: {
			textTransform: "none",
		}
	},
	components: {
		MuiCard: {
			styleOverrides: {
				root: {
					// boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
					boxShadow: "none",
					border: "1px solid #dadce0",//#e5e5e5
					// backgroundColor:"#f8f9fa",
				}

			}
		}
	}
});
const darkTheme = createTheme({
	palette: {
		mode: "dark",
	},
	typography: {
		button: {
			textTransform: "none",
		}
	}
});

type Props = {
	children: React.ReactNode,
}
export default function Init(props: Props) {
	/**
	 * Viewer取得.
	 */
	const { setViewer } = useViewer();
	const [didInit, setDidInit] = useRecoilState(didInitState);
	const { nickFetch } = useNickFetch();
	const { addNotice } = useNotice();
	useEffect(() => {
		if (didInit) return;
		(async function () {
			// なんか2回呼ばれちゃうんだけど、StrictMode入れてると2回呼んでも大丈夫かの確認のため2回呼ばれちゃうらしい（開発時のみ）
			const res = await nickFetch("/get_viewer");
			if (res.status === 401) {
				// ログインしてね.
				setDidInit(true);
			} else if (!res.error) {
				// ログインできてる.
				setViewer(new User(res.data.viewer));
				setDidInit(true);
			}
			// URLにsuccessとかerrorとかあったらスネークバー出したい.
			const params = new URLSearchParams(window.location.search);
			if ( params.get("success") ) {
				addNotice(params.get("success") as string, "success");
				window.history.replaceState(null, "", window.location.pathname);//URL置換.
			}
			if ( params.get("error") ) {
				addNotice(params.get("error") as string, "error");
				window.history.replaceState(null, "", window.location.pathname);//URL置換.
			}
		})();
	}, []);
	/**
	 * カラーモード.
	 */
	const darkMode = useRecoilState(darkModeState)[0];
	// let darkMode = useMediaQuery('(prefers-color-scheme: dark)');//こうしたらシステムの取れるよ.
	const theme = darkMode ? darkTheme : lightTheme;
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			{!didInit ? (<div className="Init" style={{ width: "100vw", height: "100vh", position: "fixed", background: "#fff" }}>
				<Box
					sx={{
						top: 0,
						left: 0,
						bottom: 0,
						right: 0,
						position: 'absolute',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						flexDirection: "column",
					}}
				>
					<CircularProgress />
					<Typography sx={{ mt: 1.5 }} variant="caption">読み込み中</Typography>
				</Box>
			</div>) : (props.children)}
		</ThemeProvider>
	);
}

