import { useNickFetch } from "#app/hooks/useNickFetch";
import { useViewer } from "#app/hooks/useViewer";
import { User, UserAuthLevel } from "#app/models";
import { Box, Button, Card, CardContent, CardHeader, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link as LinkTag } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dayjs from '#app/utils/custom_dayjs';
import CreateUserDialog from "./CreateUserDialog";

type GetUsersResponse = {
	users: User[],
}
export default function Users() {
	const { viewer } = useViewer();
	const [users, setUsers] = useState<User[]>();
	const { nickFetch } = useNickFetch();

	const getsUserList = async () => {
		const res = await nickFetch("/get_users/");
		if (!res.error) {
			// ok.
			const _users = [];
			for (const user of (res.data as GetUsersResponse).users ) {
				_users.push( new User(user) );
			}
			setUsers(_users);
		}
	}
	useEffect(() => {
		(async function () {
			await getsUserList();
		})();
	}, []);

	// 作成ダイアログ開閉.
	const [createUserDialogOpen, setCreateUserDialogOpen] = useState(false);

	return (
		<>
			<Card>
				<CardHeader
					title="管理アカウント一覧"
					titleTypographyProps={{ variant: 'subtitle1' }}
					sx={{ py: 1 }}
					action={<Button variant="outlined" size="small" onClick={() => {setCreateUserDialogOpen(true)}}>新規作成</Button>}
				/>

				<CreateUserDialog
					open={createUserDialogOpen}
					onClose={() => { setCreateUserDialogOpen(false); }}
					onComplete={getsUserList}
				/>
				<Divider />
				<CardContent>
					<TableContainer>
						<Table size="small">
							<TableHead sx={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
								<TableRow>
									<TableCell component="th">アカウント名</TableCell>
									<TableCell component="th">ログインID</TableCell>
									<TableCell component="th">権限</TableCell>
									<TableCell component="th">代理店</TableCell>
									<TableCell component="th">Google</TableCell>
									<TableCell component="th">Stripe</TableCell>
									<TableCell component="th">初期費用無料</TableCell>
									<TableCell component="th">月額費用</TableCell>
									<TableCell component="th">最終ログイン</TableCell>
									<TableCell component="th">作成日</TableCell>
									<TableCell component="th">課金開始日</TableCell>
									<TableCell component="th">操作</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{users && users.map((user) => (
									<TableRow key={user.name} sx={()=>{ return (user.status == 0 ? {background:"#ccc"} : null)}}>
										<TableCell>{user.name}</TableCell>
										<TableCell>{user.login_id}</TableCell>
										<TableCell>{user.auth_level == UserAuthLevel.MASTER ? "マスター" : "通常"}</TableCell>
										<TableCell>{user.agency_label ? user.agency_label : "-"}</TableCell>
										<TableCell>{user.google_account_id ? "済" : "-"}</TableCell>
										<TableCell>{user.stripe_customer_id ? (<LinkTag href={"https://dashboard.stripe.com/test/customers/"+user.stripe_customer_id} target="_blank" underline="none">済</LinkTag>) : "-"}</TableCell>
										<TableCell>{user.free_initial_cost ? "Yes" : "-"}</TableCell>
										<TableCell>{user.subscription_cost / 10000}万円</TableCell>
										<TableCell>{user.login_dt ? dayjs(user.login_dt).fromNow() : "-"}</TableCell>
										<TableCell>{dayjs(user.regist_dt).format("YYYY-MM-DD")}</TableCell>
										<TableCell>{user.payment_start_date ? dayjs(user.payment_start_date).format("YYYY-MM-DD") : "-"}</TableCell>
										<TableCell>
											<Button variant="outlined" size="small" component={Link} to={"/users/" + user.id}>詳細</Button>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</CardContent>
			</Card>
		</>
	);
}
