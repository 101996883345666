import NickDialog from "#app/components/NickDialog";
import { LocalPost } from "#app/models/LocalPost";
import { nl2br } from "#app/utils";
import dayjs from "#app/utils/custom_dayjs";
import { Box, Button, Card, CardActions, CardContent, CardMedia, Chip, DialogActions, DialogContent, Divider, Typography } from "@mui/material";
import { useState } from "react";

/**
 * ほぼプレビュー的な見た目にすればいいか.
 */

type Props = {
	open: boolean,
	localPost: LocalPost | null,
	onClose?: (() => void),//?を付けると任意になるんだって！
	onComplete?: (() => void),
}
export default function LoacalPostDialog(props: Props) {
	const localPost = props.localPost ? props.localPost : new LocalPost();
	const [mediaIndex, setMediaIndex] = useState<number>(0);
	// console.log(localPost);

	// 閉じる処理. 渡された関数実行するだけ.
	const handleEditClose = () => {
		if (props.onClose) props.onClose();
	};

	return (
		<>
			<NickDialog
				open={props.open}
				onClose={handleEditClose}
				fullWidth={true}
				maxWidth="xs"
			>
				<DialogContent>
					<Box sx={{ display: "flex", mb: 2 }}>
						<Chip icon={localPost.getTopicTypeIcon()} label={localPost.getTopicTypeLabel()} size="small" variant="outlined" />
						<Chip label={localPost.getStateLabel()} sx={{ ml: 1 }} size="small" variant="outlined" color={localPost.getStateColor()} />
					</Box>
					<Card sx={{ position: "relative" }}>
						{localPost.getMedia() ? (
							<>
								{/* 画像があるとき */}
								<CardMedia
									component="img"
									height="300px"
									sx={{ backgroundColor: "#FFF" }}
									image={localPost.getThumbnailUrl(mediaIndex)}
									alt="画像"
								/>
								{/* 複数枚あるとき. 切り替えボタン表示. */}
								{localPost.media && 1 < localPost.media.length ? (
									<Box textAlign="center">
										{localPost.media.map((media, i) => (
											<Button
												key={i}
												onClick={_ => { setMediaIndex(i) }}
												disabled={i === mediaIndex}
											>画像{i + 1}</Button>
										))}
									</Box>
								) : null}
								<Box sx={{ height: "80px", background: "linear-gradient(rgba(0,0,0,.54),transparent)", position: "absolute", top: 0, width: "100%" }}>
									<Typography variant="body2" color="#fff" ml={2} mt={2}>
										{localPost.createTime === localPost.updateTime ? (
											<>{dayjs(localPost.createTime).isValid() ? "投稿: " + dayjs(localPost.createTime).format("YYYY年M月D日(dd)") : ""}</>
										) : (
											<>更新: {dayjs(localPost.updateTime).format("YYYY年M月D日(dd)")}</>
										)}
									</Typography>
								</Box>
							</>
						) : (
							<>
								{/* 画像が無いとき */}
								<Typography variant="body2" ml={2} mt={2} mb={1}>
									{localPost.createTime === localPost.updateTime ? (
										<>{dayjs(localPost.createTime).isValid() ? "投稿: " + dayjs(localPost.createTime).format("YYYY年M月D日(dd)") : ""}</>
									) : (
										<>更新: {dayjs(localPost.updateTime).format("YYYY年M月D日(dd)")}</>
									)}
								</Typography>
							</>
						)}

						{/* イベント情報 */}
						{localPost.event ?
							(<CardContent sx={{ pb: 0 }}>
								<Typography variant="subtitle1">{localPost.event.title}</Typography>
								{localPost.getEventRangeLabel() ? (
									<Typography variant="caption" display="block" mt="-2px" color="text.secondary">{localPost.getEventRangeLabel()}</Typography>
								) : null}
							</CardContent>)
							: null}

						{/* 本文 */}
						<CardContent sx={{ pb: 1 }}>
							<Typography variant="body2">
								{localPost.summary ? (<>{nl2br(localPost.summary)}</>) : (<Box component="span" color="text.secondary">本文なし</Box>)}
							</Typography>
						</CardContent>

						{/* クーポン */}
						{localPost.offer ?
							(<CardContent sx={{ pb: 0 }}>
								{localPost.offer.redeemOnlineUrl ? (
									<Button href={localPost.offer.redeemOnlineUrl} target="_blank">オンラインで特典を利用</Button>
								) : null}
								{localPost.offer.couponCode ? (
									<Box sx={{ border: "2px dashed #dadce0", p: 2, backgroundColor: "rgba(0,0,0,0.05)", borderRadius: "6px", textAlign: "center" }}>
										<Typography variant="caption" color="text.secondary">このコードをお店で提示してください</Typography>
										<Typography fontSize={24} fontWeight="bolder" py={1.5}>{localPost.offer.couponCode}</Typography>
										{localPost.getEventRangeLabel() ? (
											<Typography variant="caption" color="text.secondary">有効期限: {localPost.getEventRangeLabel()}</Typography>
										) : null}
									</Box>
								) : null}
								{localPost.offer.termsConditions ? (
									<Typography fontSize={11} pt={1} color="text.secondary">
										利用規約<br />
										{localPost.offer.termsConditions}
									</Typography>
								) : null}
							</CardContent>)
							: null}

						{/* ボタン */}
						{localPost.callToAction ?
							(<CardActions sx={{ p: 2 }}>
								<Button variant="contained" href={localPost.callToAction.url} target="_blank">
									{localPost.getActionTypeLabel()}
								</Button>
							</CardActions>)
							: null}
					</Card>
				</DialogContent>
				<Divider />
				<DialogActions sx={{ py: 2, px: 3 }}>
					{localPost.searchUrl ?
						<Button sx={{ mr: "auto" }} variant="outlined" component="a" href={localPost.searchUrl} target="_blank">検索で表示</Button>
						: null}
					<Button color="inherit" onClick={handleEditClose}>閉じる</Button>
				</DialogActions>
			</NickDialog>
		</>
	);
}
