import { useRecoilState } from "recoil";
import { drawerOpenState } from "#app/states";

/**
 * 意味あんのか？
 */
export function useDrawerOpen() {
	const [drawerOpen, setDrawerOpen] = useRecoilState(drawerOpenState);
	const toggleDrawerOpen = () => {
		setDrawerOpen(!drawerOpen);
	};
	return { drawerOpen, setDrawerOpen, toggleDrawerOpen };
}