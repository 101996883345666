import { Box, Button, Typography, DialogContent, DialogActions, List, ListItem, ListItemAvatar, Avatar, ListItemText, DialogProps, CircularProgress, ListSubheader, DialogContentText, Divider, Grid, TextField } from "@mui/material";
import { useNickFetch } from "#app/hooks/useNickFetch";
import { useState } from "react";
import { useNotice } from "#app/hooks/useNotice";
import { User } from "#app/models";
import NickDialog from "#app/components/NickDialog";
import LoadingButton from "@mui/lab/LoadingButton";
import DownloadIcon from '@mui/icons-material/Download';

/**
 * components 側に置いたほうがいいのかもしれない.
 * 別の場所で使う予定もないし users/Detail/ に置いてる.
 */

type Props = {
	open: boolean,
	onComplete?: (() => void),
}
export default function CreateUserDialog(props: Props & DialogProps) {
	const { addNotice } = useNotice();
	const { nickFetch } = useNickFetch();
	const [loading, setLoading] = useState<boolean>(false);

	// 閉じる処理. 渡された関数実行するだけ.
	const handleEditClose = () => {
		if (props.onClose) props.onClose({}, "backdropClick");
	};

	/**
	 * 開いた.
	 */
	const handleOpen = function () {
		setValidateErrors({});
	}

	/**
	 * 作成.
	 */
	const [validateErrors, setValidateErrors] = useState<{ [key: string]: string }>({});
	const handleSubmit = async function (e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		const formData = new FormData(e.currentTarget);
		/**
		 * API叩く.
		 */
		const res = await nickFetch("/create_user/", {
			login_id: formData.get("login_id"),
			password: formData.get("password"),
			name: formData.get("name"),
			email: formData.get("email"),
		});
		if (!res.error) {
			if (res.data && res.data.validate_errors) {
				// バリデートエラー.
				setValidateErrors(res.data.validate_errors);
			} else {
				// ok.
				if (props.onComplete) props.onComplete(); // 親側でユーザー取り直し.
				setValidateErrors({});
				handleEditClose();
				addNotice("保存しました", "success");
			}
		}
	};
	return (
		<>
			<NickDialog
				open={props.open}
				onClose={props.onClose}
				fullWidth={true}
				maxWidth="sm"
				onOpen={handleOpen}
			>
				<Box component="form" noValidate onSubmit={handleSubmit}>
					{/* <DialogTitle>編集</DialogTitle> */}
					<Typography variant="h6" sx={{ textAlign: "center", pt: 3 }}>管理アカウント作成</Typography>
					<DialogContent>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									fullWidth
									margin="dense"//or normal
									type="text"
									autoComplete="off"
									label="名前"
									name="name"
									defaultValue=""
									error={"name" in validateErrors}
									helperText={validateErrors.name ? validateErrors.name : null}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									margin="dense"//or normal
									type="email"
									label="メールアドレス"
									name="email"
									defaultValue=""
									error={"email" in validateErrors}
									helperText={validateErrors.email ? validateErrors.email : null}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									margin="dense"//or normal
									type="text"
									autoComplete="off"
									label="ログインID"
									name="login_id"
									defaultValue=""
									error={"login_id" in validateErrors}
									helperText={validateErrors.login_id ? validateErrors.login_id : null}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									margin="dense"//or normal
									type="text"
									autoComplete="off"
									label="パスワード"
									name="password"
									defaultValue=""
									error={"password" in validateErrors}
									helperText={validateErrors.password ? validateErrors.password : null}
								/>
							</Grid>
						</Grid>
					</DialogContent>
					<Divider />
					<DialogActions sx={{ p: 2 }}>
						<Button color="inherit" onClick={handleEditClose} sx={{ marginRight: "6px" }}>キャンセル</Button>
						<Button variant="contained" type="submit">保存</Button>
					</DialogActions>
				</Box>
				
			</NickDialog>
		</>
	);
}
