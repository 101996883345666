import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

// material-ui
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/system';


const MyListItem = function (props: { icon: any, text: string, to: string, onClick: any }) {
	const theme = useTheme();
	const selectedColor = 'primary.main';
	const { icon, text, to, onClick } = props;

	// アクティブリンクかここで判定しちゃう.
	const location = useLocation();
	const _selected = location.pathname === to;

	return (
		<ListItem disablePadding sx={{borderBottom:"none"}}>{/* 1px solid #e5e5e5 */}
			<ListItemButton
				onClick={onClick}
				component={Link}
				to={to ? to : "#"}
				selected={_selected}
				sx={{
					'&:hover': {
						bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
					},
					'&.Mui-selected': {
						borderRight: `2px solid ${theme.palette.primary.main}`,
						color: selectedColor,
						'&:hover': {
							color: selectedColor,
						}
					}
				}}
			>
				<ListItemIcon sx={_selected ? { color: "inherit" } : {}}>{icon}</ListItemIcon>
				<ListItemText primary={text} />
			</ListItemButton>
		</ListItem>
	);
};

MyListItem.propTypes = {
	icon: PropTypes.object,
	text: PropTypes.string,
	to: PropTypes.string,
	selected: PropTypes.bool,
	onClick: PropTypes.func,
};

export default MyListItem;
